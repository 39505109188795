import { Injectable } from "@angular/core";
import { EnvService } from "src/app/services/env.service";
import { DomainHelper } from "../utils/domain-helper.util";

@Injectable({ providedIn: "root" })
export class SubdomainGuard {
  constructor(private readonly _envService: EnvService) {}

  public async canActivate() {
    const mainDomain = this._envService.domain;

    if (!DomainHelper.isSubdomain) {
      window.location.href = mainDomain;
      return false;
    }

    return true;
  }
}

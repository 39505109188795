import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DomainEventsConstants } from "src/app/common/constants/domain-event.constants";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { LanguageConstants } from "src/app/common/constants/language.constants";
import { EventData } from "src/app/common/models/event-data";
import { DomainHelper } from "src/app/common/utils/domain-helper.util";
import { EventBusService } from "src/app/services/event-bus.service";
import { LocalStorageService } from "src/app/services/local-storage.service";

@Component({
  selector: "app-language-selector",
  templateUrl: "./language-selector.component.html",
  styleUrls: ["./language-selector.component.css"],
})
export class LanguageSelectorComponent implements OnInit {
  public languages: string[] = [LanguageConstants.RUSSIAN, LanguageConstants.UZBEK];
  public currentLanguage: LanguageConstants = "ru";

  constructor(
    private readonly _translate: TranslateService,
    private readonly _localStorage: LocalStorageService,
    private readonly _eventBusService: EventBusService
  ) {}

  async ngOnInit() {
    const lsLang = await this._localStorage.getLanguage();
    if (lsLang) {
      this.currentLanguage = lsLang;
      this._translate.use(lsLang as string);
    } else {
      this.currentLanguage = this._translate.getDefaultLang();
    }
  }

  async changeLanguage(language: LanguageConstants) {
    this._translate.use(language as string);
    this.currentLanguage = language;
    await this._localStorage.saveLanguage(language);

    this._eventBusService.dispatch(new EventData(EventTypeConstants.ChangeLanguage, this.currentLanguage));

    const subdomainFrame = document.getElementById("subdomainFrame") as HTMLIFrameElement;
    subdomainFrame?.contentWindow?.postMessage(
      { key: DomainEventsConstants.language, params: this.currentLanguage },
      DomainHelper.eventsListenerUrl
    );
  }
}

import { Component, OnInit } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { EnvService } from "./services/env.service";
import { LocalStorageService } from "./services/local-storage.service";
import { DomainHelper } from "./common/utils/domain-helper.util";
import { EventBusService } from "./services/event-bus.service";
import { EventTypeConstants } from "./common/constants/event-type.constants";
import { EventData } from "./common/models/event-data";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  public domainEventsListenerUrl = DomainHelper.eventsListenerUrl;
  public showFullscreenLoader = false;

  constructor(
    private readonly _update: SwUpdate,
    private readonly _envService: EnvService,
    private readonly _localStorage: LocalStorageService,
    private readonly _eventBusService: EventBusService
  ) {
    if (this._update.isEnabled && this._envService.isProduction) {
      this._update.versionUpdates.subscribe(evt => {
        switch (evt.type) {
          case "VERSION_READY":
            this._update.activateUpdate().then(() => {
              document.location.reload();
            });
            break;
        }
      });
    }

    this._eventBusService.handle(EventTypeConstants.ToggleFullscreenLoader, (show: boolean) => {
      this.showFullscreenLoader = show;
      if (show) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    });
  }

  async ngOnInit() {
    if (DomainHelper.isChromeBrowser) {
      return;
    }

    const language = new URLSearchParams(window.location.search).get("language");
    if (language) {
      await this._localStorage.saveLanguage(language);
      this._eventBusService.dispatch(new EventData(EventTypeConstants.ChangeLanguage, language));
    }
  }

  onMyFrameLoad(): void {
    console.log("Shop domain frame loaded");
  }
}

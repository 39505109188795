<div class="flex flex-col gap-y-5">
  <h4 class="underlined-btn underlined-btn_active">{{ "Receive.Title" | translate }}</h4>
  <p>{{ "Receive.Subtitle" | translate: { currency: currencyName, network: networkName } }}</p>

  <hr class="border-border-input-color" />

  <div class="space-y-5">
    <p class="text-center text-xl">{{ "Receive.Select_network" | translate }}</p>
    <div class="grid grid-cols-2 items-center justify-center gap-5">
      <button
        class="rounded-main px-5 py-2 text-2xl font-bold text-text-secondary max-md:text-base"
        [ngClass]="selectedWallet.network === Network.Tron ? 'bg-tron' : 'bg-select-bg'"
        (click)="selectNetwork(Network.Tron)"
      >
        TRON
      </button>
      <button
        class="rounded-main px-5 py-2 text-2xl font-bold text-text-secondary max-md:text-base"
        [ngClass]="selectedWallet.network === Network.Polygon ? 'bg-polygon' : 'bg-select-bg'"
        (click)="selectNetwork(Network.Polygon)"
      >
        Polygon
      </button>
      <button
        class="rounded-main px-5 py-2 text-2xl font-bold text-text-secondary max-md:text-base"
        [ngClass]="selectedWallet.network === Network.Ton ? 'bg-ton' : 'bg-select-bg'"
        (click)="selectNetwork(Network.Ton)"
      >
        Ton
      </button>
      <button
        class="rounded-main px-5 py-2 text-2xl font-bold text-text-secondary max-md:text-base"
        [ngClass]="selectedWallet.network === Network.Bitcoin ? 'bg-bitcoin' : 'bg-select-bg'"
        (click)="selectNetwork(Network.Bitcoin)"
      >
        Bitcoin
      </button>
    </div>
    <p class="flex flex-wrap items-center gap-x-1 max-md:justify-between">
      <span class="text-xl max-md:text-base">{{ "Wallet.Address" | translate }}:</span>
      <a
        [href]="walletLink"
        target="_blank"
        class="max-w-[70%] truncate text-base font-bold text-link-color max-md:order-1 max-md:mb-2 max-md:w-full max-md:max-w-[calc(100vw-6rem)]"
      >
        {{ selectedWallet.address }}
      </a>
      <app-skeleton
        *ngIf="!selectedWallet.address"
        class="w-[70%] max-md:order-1 max-md:mb-2 max-md:w-full"
      />
      <app-copy-btn [value]="selectedWallet.address" />
    </p>
  </div>

  <hr class="border-border-input-color" />

  <img
    *ngIf="qrBase64; else qrSkeleton"
    class="mx-auto block w-[19.375rem] max-md:w-56"
    [src]="qrBase64"
    alt="QR Code"
  />
  <ng-template #qrSkeleton>
    <app-skeleton class="mx-auto block aspect-square w-[19.375rem] max-md:w-56" />
  </ng-template>

  <hr class="border-border-input-color" />

  <app-balance-card
    *ngFor="let balance of selectedWallet.balances"
    [isShort]="true"
    [balanceItem]="balance"
  />

  <button
    *ngIf="
      !isTelegramMiniApp &&
      (selectedWallet?.network === Network.Ton || selectedWallet?.network === Network.Bitcoin)
    "
    [disabled]="isTonWalletConnected"
    class="flex items-center gap-x-4 rounded-2xl bg-input-bg p-4"
    (click)="receiveFromTonWallet()"
  >
    <img src="assets/images/ton-wallet-medium.png" alt="" class="w-9" />
    <div>
      <h4 class="!m-0 !mb-1 font-semibold text-ton">Получить через "Кошелек"</h4>
      <p class="!m-0 text-sm opacity-35">Откроет приложение "Кошелек"</p>
    </div>
  </button>

  <button class="btn w-full" (click)="closeModal()">{{ "Common.Close" | translate }}</button>

  <hr class="border-border-input-color" />
  <p>{{ "Receive.Commission" | translate: { network: networkName } }}</p>
</div>

import { Component, EventEmitter, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ApprovePhoneDto } from "src/app/common/DTO/auth/approve-phone.dto";
import { LoginDto } from "src/app/common/DTO/auth/login.dto";
import { SendConfirmCodeDto } from "src/app/common/DTO/auth/send-confirm-code.dto";
import { ValidateLoginCredentialsDto } from "src/app/common/DTO/auth/validate-login-credentials.dto";
import { Constants } from "src/app/common/constants/constants";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { ValidateConstants } from "src/app/common/constants/validate.constants";
import { AuthErrorCode } from "src/app/common/enums/auth-error-code.enum";
import { TimeHelperUtil } from "src/app/common/utils/time-helper.util";
import { lengthEqualValidator } from "src/app/common/validators/length-equal.validator";
import { numericStrValidator } from "src/app/common/validators/numeric-str.validator";
import { validCodeRangeValidator } from "src/app/common/validators/valid-code-range.validator";
import { validOtpValidator } from "src/app/common/validators/valid-otp.validator";
import { AuthService } from "src/app/services/auth.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { SessionService } from "src/app/services/session.service";
import { UserService } from "src/app/services/user.service";
import { MyidModalComponent } from "src/app/components/myid-modal/myid-modal.component";
import { DomainEventsConstants } from "src/app/common/constants/domain-event.constants";
import { DomainHelper } from "src/app/common/utils/domain-helper.util";
import { EventBusService } from "src/app/services/event-bus.service";
import { EventData } from "src/app/common/models/event-data";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.css"],
})
export class LoginFormComponent {
  step: "input" | "otp" = "input";
  inputForm: FormGroup;
  otpForm: FormGroup;
  isPending: boolean = false;
  businessError: string | null = null;
  canSendCodeAgain: boolean = true;
  otpInputCount: number = 0;
  otpTimer: number = 0;

  @Output() hideOtherAuthVariants = new EventEmitter<boolean>();
  @Output() onSuccessfulLogin = new EventEmitter();

  public readonly phoneCode = Constants.UzsPhoneCode;
  public readonly validateConstants = ValidateConstants;

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _authService: AuthService,
    private readonly _localStorage: LocalStorageService,
    private readonly _userService: UserService,
    private readonly _routes: Router,
    private readonly _sessionService: SessionService,
    private readonly _modalService: NgbModal,
    private readonly _eventBusService: EventBusService
  ) {
    this.inputForm = new FormGroup({
      phoneNumber: new FormControl(null, [
        Validators.required,
        numericStrValidator(),
        lengthEqualValidator(ValidateConstants.PhoneLengthWithoutCode),
      ]),
      psw: new FormControl(null, [
        Validators.required,
        Validators.minLength(ValidateConstants.MinPswLength),
        Validators.maxLength(ValidateConstants.MaxPswLength),
      ]),
    });

    this.otpForm = new FormGroup({
      code: new FormControl(null, [
        Validators.required,
        numericStrValidator(),
        validCodeRangeValidator(),
        validOtpValidator(),
      ]),
    });
  }

  public get phoneNumberError() {
    const control = this.inputForm.get("phoneNumber");

    if (!control?.touched) {
      return null;
    }

    if (control?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    if (control?.hasError("numericStr")) {
      return this._translateService.instant("Login.Phone_numbers_error");
    }
    if (control?.hasError("lengthEqual")) {
      return this._translateService.instant("Login.Phone_length_error");
    }
    return null;
  }

  public get pswError() {
    const control = this.inputForm.get("psw");

    if (!control?.touched) {
      return null;
    }

    if (control?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    if (control?.hasError("minlength")) {
      return this._translateService.instant("Login.Password_length_error");
    }
    if (control?.hasError("maxlength")) {
      return this._translateService.instant("Login.Password_length_error");
    }
    return null;
  }

  public get codeError() {
    const control = this.otpForm.get("code");

    if (!control?.touched) {
      return null;
    }

    if (control?.hasError("required")) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    if (control?.hasError("numericStr")) {
      return this._translateService.instant("Common.Field_not_correct");
    }
    if (control?.hasError("validCodeRange")) {
      return this._translateService.instant("Common.Field_not_correct");
    }
    if (control?.hasError("validOtp")) {
      return this._translateService.instant("Common.Field_not_correct");
    }
    return null;
  }

  onBack() {
    this.step = "input";
    this.hideOtherAuthVariants.emit(false);
  }

  async submitCredentials() {
    if (this.inputForm.invalid) {
      return;
    }

    this.isPending = true;
    const dto: ValidateLoginCredentialsDto = {
      phoneNumber: this.phoneCode + this.inputForm.get("phoneNumber")?.value,
      psw: this.inputForm.get("psw")?.value,
    };

    const validationResponse = await this._authService.validateLoginCredentials(dto);
    if (validationResponse.withError) {
      if (validationResponse.errorCode == AuthErrorCode.IncorrectPhoneOrPsw) {
        this.businessError = this._translateService.instant("Login.Wrong_phone_or_pass");
      } else if (validationResponse.errorCode == AuthErrorCode.UserNotApproved) {
        this.businessError = this._translateService.instant("Login.User_not_approved");
      } else {
        this.businessError = this._translateService.instant("Common.Unknown_error");
      }
      this.isPending = false;
      return;
    }

    const result = await this.sendConfirmCode();
    this.isPending = false;

    if (result) {
      this.step = "otp";
      this.otpTimer = Constants.ConfirmCodeLifeTimeInSec;
      this.hideOtherAuthVariants.emit(true);
      this.businessError = null;
    }
  }

  async submitOtp() {
    if (this.otpForm.invalid) {
      return;
    }

    // Check if captcha is not bypassed
    if (this.otpInputCount >= ValidateConstants.MaxOtpTries) {
      this.businessError = "Каптча не пройдена";
      return;
    }

    this.isPending = true;
    const dto: LoginDto = {
      phoneNumber: this.phoneCode + this.inputForm.get("phoneNumber")?.value,
      psw: this.inputForm.get("psw")?.value,
      code: this.otpForm.get("code")?.value,
    };
    const response = await this._authService.login(dto);

    if (!response.withError) {
      this._eventBusService.dispatch(new EventData(EventTypeConstants.ToggleFullscreenLoader, true));

      await this._localStorage.saveTokens(response.params!);
      this._sessionService.resetTimeout();
      // Send tokens to subdomain
      const subdomainFrame = document.getElementById("subdomainFrame") as HTMLIFrameElement;
      const testData = { key: DomainEventsConstants.authTokens, params: response.params };
      subdomainFrame?.contentWindow?.postMessage(testData, DomainHelper.eventsListenerUrl);

      const getMeResponse = await this._userService.getMe();
      if (getMeResponse.withError) {
        return;
      }
      await this._localStorage.saveUserData(getMeResponse.params!);
      // Send user data to subdomain
      subdomainFrame?.contentWindow?.postMessage(
        { key: DomainEventsConstants.userData, params: getMeResponse.params },
        DomainHelper.eventsListenerUrl
      );

      if (getMeResponse.params?.kyc) {
        this._routes.navigate([RouteConstants.wallet]);
        this.onSuccessfulLogin.emit();
      } else {
        this._eventBusService.dispatch(new EventData(EventTypeConstants.ToggleFullscreenLoader, false));
        this._modalService.dismissAll();
        this._modalService.open(MyidModalComponent);
      }
      return;
    }

    this.isPending = false;
    this._eventBusService.dispatch(new EventData(EventTypeConstants.ToggleFullscreenLoader, false));

    if (response.errorCode == AuthErrorCode.IncorrectPhoneOrPsw) {
      this.businessError = this._translateService.instant("Login.Wrong_phone_or_pass");
      this.clearValidators();
      this.step = "input";
      return;
    }

    if (response.errorCode == AuthErrorCode.UserNotApproved) {
      await this.tryApproveUser();
      return;
    }

    if (response.errorCode == AuthErrorCode.IncorrectCode) {
      // If user input wrong OTP, then increment OTP inputs counter
      this.otpInputCount++;
      this.businessError = this._translateService.instant("Login.Wrong_code_or_expired");
      return;
    }

    this.businessError = this._translateService.instant("Common.Unknown_error");
  }

  public async sendConfirmCode(): Promise<boolean> {
    const dto = new SendConfirmCodeDto();
    dto.phoneNumber = this.phoneCode + this.inputForm.get("phoneNumber")?.value;

    const response = await this._authService.sendConfirmCode(dto);
    this.otpTimer = Constants.ConfirmCodeLifeTimeInSec;
    if (response.withError) {
      switch (response.errorCode) {
        case AuthErrorCode.CodeRequestLimitExceeds:
          this.otpTimer = TimeHelperUtil.getTimeDiffInSec(response.params!);
          break;
        case AuthErrorCode.UserNotFound:
          this.businessError = "userNotFound";
          return false;
        case AuthErrorCode.DisallowSmsSentTo:
          this.businessError = "disallowSmsSent";
          return false;
        default:
          this.businessError = this._translateService.instant("Common.Unknown_error");
          return false;
      }
    }

    this.canSendCodeAgain = false;
    return true;
  }

  public captchaResolved(captchaResponse: string) {
    if (captchaResponse && captchaResponse != "") {
      this.otpInputCount = 0;
    }
  }

  private clearValidators() {
    this.inputForm.reset();
    this.otpForm.reset();
  }

  private async tryApproveUser() {
    const dto: ApprovePhoneDto = {
      phoneNumber: this.phoneCode + this.inputForm.get("phoneNumber")?.value,
      code: this.otpForm.get("code")?.value,
    };
    const response = await this._authService.approvePhone(dto);

    if (!response.withError) {
      this.businessError = "";
      const result = await this.sendConfirmCode();
      if (!result) {
        this.step = "input";
      }
      return;
    }

    if (response.errorCode == AuthErrorCode.IncorrectCode) {
      this.businessError = this._translateService.instant("Login.Wrong_code_or_expired");
      return;
    }

    this.businessError = this._translateService.instant("Common.Unknown_error");
  }

  ngOnDestroy() {
    this.step = "input";
    this.clearValidators();
  }
}

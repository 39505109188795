import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { generate } from "lean-qr";
import { toSvgDataURL } from "lean-qr/extras/svg";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { Network } from "src/app/common/enums/network.enum";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { getNetworkName } from "src/app/common/utils/network-name-helper";
import { TelegramMiniAppHelper } from "src/app/common/utils/telegram-mini-app-helper.util";
import { getWalletLink } from "src/app/common/utils/wallet-link-helper.util";
import { TonConnectService } from "src/app/services/ton-connect.service.ts.service";
import { WalletService } from "src/app/services/wallet.service";

@Component({
  selector: "app-receive-modal",
  templateUrl: "./receive-modal.component.html",
  styleUrls: ["./receive-modal.component.css"],
})
export class ReceiveModalComponent implements OnInit {
  internalError = false;
  wallets: WalletDto[] = [];
  qrBase64 = "";
  selectedWallet: WalletDto = new WalletDto();
  Network = Network;
  isTelegramMiniApp = TelegramMiniAppHelper.isMiniApp();
  isTonWalletConnected = false;

  constructor(
    private readonly _activeModal: NgbActiveModal,
    private readonly _walletService: WalletService,
    private readonly _tonConnectService: TonConnectService
  ) {}

  async ngOnInit() {
    const wallets = await this._walletService.getMy();
    this.wallets = wallets?.params || [];
    this.selectedWallet = this.wallets[0];
    this.generateQr(this.selectedWallet.address);
  }

  closeModal(): void {
    this._activeModal.close();
  }

  get walletLink() {
    return getWalletLink(this.selectedWallet.network, this.selectedWallet.address);
  }

  get networkName(): string {
    return getNetworkName(this.selectedWallet.network);
  }

  get currencyName(): string {
    return getCurrencyName(this.selectedWallet.balances[0]?.currency);
  }

  selectNetwork(network: Network): void {
    this.selectedWallet = this.wallets.find(x => x.network === network) || new WalletDto();
    this.generateQr(this.selectedWallet.address);
  }

  private generateQr(address: string) {
    const code = generate(address);
    this.qrBase64 = toSvgDataURL(code);
  }

  async receiveFromTonWallet() {
    if (!this._tonConnectService?.tonConnectUi?.connected) {
      console.log("wallet is not connected");
      return;
    }
    try {
      await this._tonConnectService?.tonConnectUi?.sendTransaction?.({
        validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
        messages: [
          {
            address: this.selectedWallet.address,
            amount: "1000000",
          },
        ],
      });
    } catch (error) {
      console.error("ton transaction error:", error);
    }
  }
}

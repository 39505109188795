import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {EnvService} from "src/app/services/env.service";
import {Constants} from "src/app/common/constants/constants";
import {SendKycDto} from "src/app/common/DTO/kyc/send-kyc.dto";
import {HttpResultDto} from "src/app/common/DTO/http-result.dto";
import {KycErrorCode} from "src/app/common/enums/kyc-error-code.enum";
import {UrnConstants} from "src/app/common/constants/urn.constants";
import {firstValueFrom} from "rxjs";
import {ApiResponseDto} from "src/app/common/DTO/api-response.dto";
import {ErrorParserUtil} from "src/app/common/utils/error-parser.util";
import {KycDto} from "src/app/common/DTO/kyc/kyc.dto";
import {PagedListDto} from "src/app/common/DTO/paged-list.dto";
import {VerdictKycDto} from "src/app/common/DTO/kyc/verdict-kyc.dto";
import {MyIdQrPayloadDto} from "src/app/common/DTO/kyc/my-id-qr-payload.dto";

@Injectable({
  providedIn: "root",
})
export class KycService {
  private readonly _baseReqOpts: { headers: HttpHeaders };
  private readonly _formDataReqOpts: { headers: HttpHeaders };
  private readonly KycAlreadyAddedFailMsg = "Can't accept KYC reason:";

  constructor(private readonly _httpClient: HttpClient, private readonly _envService: EnvService) {
    this._baseReqOpts = {
      headers: new HttpHeaders(Constants.JsonContentTypeHeader),
    };
    this._formDataReqOpts = {
      headers: new HttpHeaders(Constants.FormDataContentTypeHeader),
    };
  }

  public async verifyKyc(code: string, state: string): Promise<boolean> {
    const params = `?code=${code}&state=${state}`;
    const uri = `${this._envService.serverUrl}${UrnConstants.VerifyKyc}${params}`;

    try {
       const res = (await firstValueFrom(this._httpClient.get(uri))) as ApiResponseDto<boolean>;
       return res.data;
    } catch (e) {
      return false;
    }
  }

  public async buildMyIdQrPayload(): Promise<HttpResultDto<KycErrorCode, MyIdQrPayloadDto>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.GenerateMyIdQrPayload}`;
    const result = new HttpResultDto<KycErrorCode, MyIdQrPayloadDto>(false);

    try {
      const response = (await firstValueFrom(
        this._httpClient.post(uri, null, this._formDataReqOpts))) as ApiResponseDto<MyIdQrPayloadDto>;
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async sendKyc(dto: SendKycDto): Promise<HttpResultDto<KycErrorCode, number>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.AddKyc}`;
    const result = new HttpResultDto<KycErrorCode, number>(false);

    const data = new FormData();
    data.append("file", dto.file);
    data.append("name", dto.name);
    data.append("passId", dto.passId);

    try {
      const response = (await firstValueFrom(
        this._httpClient.post(uri, data, this._formDataReqOpts)
      )) as ApiResponseDto<number>;
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async getAllKyc(
    size: number,
    page: number,
  ): Promise<HttpResultDto<KycErrorCode, PagedListDto<KycDto> | null>> {
    let params = `?size=${size}&index=${page}`;
    const uri = `${this._envService.serverUrl}${UrnConstants.AllKyc}${params}`;
    const result = new HttpResultDto<KycErrorCode, PagedListDto<KycDto> | null>(false);

    try {
      const response = (await firstValueFrom(this._httpClient.get(uri, this._baseReqOpts))) as ApiResponseDto<
        PagedListDto<KycDto>
      >;
      result.params = response.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async getKycById(id: number): Promise<HttpResultDto<KycErrorCode, KycDto | null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.GetKycById}/${id}`;
    const result = new HttpResultDto<KycErrorCode, KycDto | null>(false);

    try {
      const res = (await firstValueFrom(this._httpClient.get(uri))) as ApiResponseDto<KycDto>;
      result.params = res.data;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  public async verdictKyc(verdictBody: VerdictKycDto): Promise<HttpResultDto<KycErrorCode, null>> {
    const uri = `${this._envService.serverUrl}${UrnConstants.VerdictKyc}`;
    const result = new HttpResultDto<KycErrorCode, null>(false);

    try {
      (await firstValueFrom(
        this._httpClient.post(uri, verdictBody, this._baseReqOpts)
      )) as ApiResponseDto<null>;
    } catch (e) {
      const apiError = ErrorParserUtil.parse(e);
      result.withError = true;
      result.errorCode = this.getErrorCode(apiError.msg);
    }

    return result;
  }

  private getErrorCode(error: string): KycErrorCode {
    if (error.includes(this.KycAlreadyAddedFailMsg)) {
      return KycErrorCode.KycAlreadyAdded;
    }

    switch (error) {
      case Constants.InternalServerError:
        return KycErrorCode.InternalError;
      case Constants.Unauthorized:
        return KycErrorCode.Unauthorized;
      case "Already confirm":
        return KycErrorCode.KycAlreadyAdded;
      case "User not found":
        return KycErrorCode.UserNotFound;
      default:
        return KycErrorCode.InternalError;
    }
  }
}

<button
  (click)="onClose()"
  class="absolute right-0 top-[-4.5rem] !m-0 flex h-16 w-16 items-center justify-center rounded-tw bg-select-bg"
>
  <img src="assets/icons/close-circle-outline-white.svg" alt="close" class="h-7 w-7" />
</button>

<div class="flex flex-col gap-y-6">
  <h2 class="underlined-btn underlined-btn_active text-center">{{ "Kyc.Verification" | translate }}</h2>
  <p class="text-xl">
    {{ "Kyc.Download_app" | translate }}
  </p>

  <div class="grid grid-cols-[6.25rem_6.25rem_1fr] items-center gap-x-5">
    <img src="assets/images/myid-logo-2.jpg" alt="MyId logo" />
    <img src="assets/images/arrow.png" alt="arrow" />
    <img src="assets/images/myid-qr-2.png" alt="MyId QR" />
  </div>

  <div class="grid grid-cols-2 gap-x-4">
    <a
      href="https://apps.apple.com/ru/app/myid-ozbekiston/id1562842955"
      target="_blank"
      rel="noopener noreferrer"
      class="rounded-md border border-border-input-color px-10 py-3"
    >
      <img src="assets/images/app-store.png" alt="download on the app store" />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=uz.uzinfocom.myid"
      target="_blank"
      rel="noopener noreferrer"
      class="rounded-md border border-border-input-color px-10 py-3"
    >
      <img src="assets/images/play-market.png" alt="download on the play market" />
    </a>
  </div>

  <p class="text-xl">{{ "Kyc.After_downloading" | translate }}</p>

  <a [href]="myIdLink" target="_blank" class="btn">{{ "Common.Next" | translate }}</a>
</div>

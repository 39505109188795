import { Component, OnInit } from "@angular/core";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { TronService } from "src/app/services/tron.service";
import { Transaction } from "src/app/common/models/transaction";
import { CryptoTxDto } from "src/app/common/DTO/crypto/crypto-tx.dto";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { PlatformType } from "src/app/common/enums/platform-type.enum";
import { Network } from "src/app/common/enums/network.enum";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { RatesService } from "src/app/services/rates.service";
import { getNetworkName } from "src/app/common/utils/network-name-helper";
import { getWalletLink } from "src/app/common/utils/wallet-link-helper.util";
import { ReceiveModalComponent } from "src/app/components/receive-modal/receive-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SendModalComponent } from "src/app/components/send-modal/send-modal.component";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { PolygonService } from "src/app/services/polygon.service";
import { WalletService } from "src/app/services/wallet.service";

@Component({
  templateUrl: "./wallet.component.html",
  styleUrls: ["./wallet.component.css"],
})
export class WalletComponent implements OnInit {
  public internalError = false;
  public wallets: WalletDto[] = [];
  public platformType = PlatformType;
  public rates: RateDto[] = [];

  public readonly TxCount = 4;

  private trxTxs: Transaction[] = [];
  private usdtTxs: Transaction[] = [];
  private maticTxs: Transaction[] = [];
  private polygonUsdtTxs: Transaction[] = [];
  public isTxsLoading = true;
  public buySellTxs: CryptoTxDto[] = [];

  public currentPlatformType = PlatformType.DEPOSITARY;

  constructor(
    private readonly _tronService: TronService,
    public readonly _localStorage: LocalStorageService,
    public readonly _ratesService: RatesService,
    private readonly _modalService: NgbModal,
    private readonly _polygonService: PolygonService,
    private readonly _walletService: WalletService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.wallets = (await this._walletService.getMy()).params ?? [];
    this.rates = (await this._ratesService.getRates()).params ?? [];

    this.currentPlatformType = await this._localStorage.getPlatformType();
    this.loadTxs();
  }

  public get totalUzsBalance(): number {
    let sum = 0;
    if (this.rates.length === 0 || this.wallets.length === 0) {
      return sum;
    }
    for (const wallet of this.wallets) {
      for (const balance of wallet.balances) {
        sum += ConvertCurrencyHelper.convertToUzs(balance.amount, balance.currency, this.rates);
      }
    }
    return sum;
  }

  public get txs() {
    const groupedTxs = [...this.trxTxs, ...this.usdtTxs, ...this.maticTxs, ...this.polygonUsdtTxs];
    return groupedTxs.sort((a, b) => b.timestamp - a.timestamp).slice(0, this.TxCount);
  }

  public returnNetwork(network: Network) {
    return getNetworkName(network);
  }

  public returnWalletLink(network: Network, address: string) {
    return getWalletLink(network, address);
  }

  public openReceiveModal() {
    this._modalService.open(ReceiveModalComponent);
  }

  public openSendModal() {
    this._modalService.open(SendModalComponent);
  }

  public get hasTxs() {
    return this.txs.length > 0;
  }

  private async loadTxs() {
    this.isTxsLoading = true;
    const trxAddress = this.wallets.find(x => x.network === Network.Tron)?.address;
    const polygonAddress = this.wallets.find(x => x.network === Network.Polygon)?.address;

    if (!trxAddress || !polygonAddress) {
      this.isTxsLoading = false;
      return;
    }

    const trxTxsRes = await this._tronService.getTrxTransactions(trxAddress, true);
    this.trxTxs = trxTxsRes.items.slice(0, this.TxCount);

    const usdtTxsRes = await this._tronService.getUsdtTransactions(trxAddress, true);
    this.usdtTxs = usdtTxsRes.items.slice(0, this.TxCount);

    const maticTxsRes = await this._polygonService.getMaticTransactions(polygonAddress, 1);
    this.maticTxs = maticTxsRes.slice(0, this.TxCount);

    const polygonUsdtTxsRes = await this._polygonService.getUsdtTransactions(polygonAddress, 1);
    this.polygonUsdtTxs = polygonUsdtTxsRes.slice(0, this.TxCount);

    this.isTxsLoading = false;
  }
}

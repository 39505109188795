import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "./env.service";
import { ethers } from "ethers";
import { firstValueFrom } from "rxjs";
import { Transaction } from "src/app/common/models/transaction";
import { CryptoToken } from "src/app/common/enums/crypto-token.enum";
import { TxType } from "src/app/common/enums/tx-type.enum";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { Network } from "src/app/common/enums/network.enum";
import { TonUsdtAbi, TonUsdtAddress } from "../common/constants/ton-usdt.constant";
import { NotAddress } from "../common/constants/not.constant";
import { ToncenterRawTxListDto } from "../common/DTO/toncenter/toncenter-raw-tx-list.dto";
import { ToncenterRawTxDto } from "../common/DTO/toncenter/toncenter-raw-tx.dto";
import { ToncenterJettonTxListDto } from "../common/DTO/toncenter/toncenter-jetton-tx-list.dto";
import { ToncenterJettonTxDto } from "../common/DTO/toncenter/toncenter-jetton-tx.dto";

@Injectable({
  providedIn: "root",
})
export class TonService {
  private readonly txPerPage = 10;
  private readonly notContractAddress = NotAddress;
  private readonly usdtContractAddress = TonUsdtAddress;
  private readonly tonRpc = "https://ton-rpc.com";
  private tonProvider: ethers.providers.JsonRpcProvider;

  constructor(
    private readonly _http: HttpClient,
    private readonly _env: EnvService
  ) {
    this.tonProvider = new ethers.providers.JsonRpcProvider(this.tonRpc);
  }

  public async getTonTransactions(wallet: string, page: number): Promise<Transaction[]> {
    const txs: Transaction[] = [];

    let response = await this.getTonTxs(wallet, page, this.txPerPage, false);
    if (!response) return [];

    // If the rate limit reached, try again with the API key
    // if (
    //   typeof response.result === "string" &&
    //   response.result.includes("Max rate limit reached, please use API Key for higher rate limit")
    // ) {
    //   response = await this.getTonTxs(wallet, page, this.txPerPage, true);
    // }
    if (!response) return [];

    for (const tx of response.transactions) {
      const dto = new ToncenterRawTxDto();
      Object.assign(dto, tx);
      const parsed = this.parseRawTx(dto, wallet);
      txs.push(parsed);
    }

    return txs;
  }

  public async getNotTransactions(wallet: string, page: number): Promise<Transaction[]> {
    const txs: Transaction[] = [];

    let response = await this.getNotTxs(wallet, page, this.txPerPage, false);
    if (!response) return [];

    // if (
    //   typeof response.result === "string" &&
    //   response.result.includes("Max rate limit reached, please use API Key for higher rate limit")
    // ) {
    //   response = await this.getUsdtTxs(wallet, page, this.txPerPage, true);
    // }
    if (!response) return [];

    for (const tx of response.jetton_transfers) {
      const dto = new ToncenterJettonTxDto();
      Object.assign(dto, tx);
      const parsed = this.parseJettonTx(dto, wallet, CryptoToken.Not);
      txs.push(parsed);
    }

    return txs;
  }

  public async getUsdtTransactions(wallet: string, page: number): Promise<Transaction[]> {
    const txs: Transaction[] = [];

    let response = await this.getUsdtTxs(wallet, page, this.txPerPage, false);
    if (!response) return [];

    // if (
    //   typeof response.result === "string" &&
    //   response.result.includes("Max rate limit reached, please use API Key for higher rate limit")
    // ) {
    //   response = await this.getUsdtTxs(wallet, page, this.txPerPage, true);
    // }
    if (!response) return [];

    for (const tx of response.jetton_transfers) {
      const dto = new ToncenterJettonTxDto();
      Object.assign(dto, tx);
      const parsed = this.parseJettonTx(dto, wallet, CryptoToken.Not);
      txs.push(parsed);
    }

    return txs;
  }

  public async getGasPrice(): Promise<number | null> {
    let response = await this.requestGasPrice(false);
    if (!response) return null;

    // if (
    //   typeof response.result === "string" &&
    //   response.result.includes("Max rate limit reached, please use API Key for higher rate limit")
    // ) {
    //   response = await this.requestGasPrice(true);
    // }
    // if (!response || typeof response.result === "string") return null;

    return response;
  }

  public async estimateTonTransferCost(
    amount: string,
    networkGasPrice: number,
    toAddress?: string
  ): Promise<number> {
    let gasAmount = 21000;

    if (toAddress && amount) {
      try {
        const transaction = {
          to: toAddress,
          value: ethers.utils.parseEther(amount),
          gasPrice: ethers.utils.parseUnits(networkGasPrice.toString(), "gwei"),
        };
        gasAmount = (await this.tonProvider.estimateGas(transaction)).toNumber();
      } catch (error) {
        console.log(error);
      }
    }

    const gasPrice = networkGasPrice / 1e9;
    const fee = +gasAmount * +gasPrice;
    return fee;
  }

  public async estimatePolygonUsdtTransferCost(
    amount: string,
    networkGasPrice: number,
    fromAddress?: string,
    toAddress?: string
  ): Promise<number> {
    const usdtContract = new ethers.Contract(TonUsdtAddress, TonUsdtAbi, this.tonProvider);
    let gasAmount = 77578;

    if (fromAddress && toAddress && amount) {
      try {
        gasAmount = (
          await usdtContract.estimateGas["transfer"](toAddress, ethers.utils.parseUnits(amount, 6))
        ).toNumber();
      } catch (error) {
        try {
          const manualGasLimit = 100000;
          const tx = {
            from: fromAddress,
            to: usdtContract.address,
            data: usdtContract.interface.encodeFunctionData("transfer", [
              toAddress,
              ethers.utils.parseUnits(amount, 6),
            ]),
            gasPrice: ethers.utils.parseUnits(networkGasPrice.toString(), "gwei"),
            gasLimit: manualGasLimit,
          };
          gasAmount = (await this.tonProvider.estimateGas(tx)).toNumber();
        } catch (error) {
          console.log(error);
        }
      }
    }

    const gasPrice = networkGasPrice / 1e9;
    const fee = +gasAmount * +gasPrice;
    return fee;
  }

  private parseRawTx(tx: ToncenterRawTxDto, userWallet: string): Transaction {
    const txDto = new Transaction();

    txDto.from = tx.account;
    txDto.to = tx.account;
    txDto.hash = tx.hash;
    txDto.createdAt = new Date(0 * 1000);
    txDto.timestamp = 0 * 1000;
    txDto.fee = Number(tx.total_fees);
    txDto.id = tx.trace_id;
    txDto.type = tx.account.toLowerCase() === userWallet.toLowerCase() ? TxType.Out : TxType.In;
    txDto.network = Network.Ton;
    txDto.amount = ethers.utils.formatUnits(
      Number(tx.account_state_before.balance) - Number(tx.account_state_after.balance) || 0
    );
    txDto.status = tx.end_status == "0" ? TxStatus.Approved : TxStatus.Canceled;
    txDto.token = CryptoToken.Ton;
    // txDto.isCommission = tx.methodId === "0xa9059cbb";
    txDto.isCommission = false;

    return txDto;
  }

  private parseJettonTx(tx: ToncenterJettonTxDto, userWallet: string, token: CryptoToken): Transaction {
    const txDto = new Transaction();

    txDto.from = tx.source_wallet;
    txDto.to = tx.response_destination;
    txDto.hash = tx.transaction_hash;
    txDto.createdAt = new Date(0 * 1000);
    txDto.timestamp = 0 * 1000;
    txDto.fee = 0 * 0;
    txDto.id = tx.trace_id;
    txDto.type = tx.source_wallet.toLowerCase() === userWallet.toLowerCase() ? TxType.Out : TxType.In;
    txDto.network = Network.Ton;
    txDto.amount = ethers.utils.formatUnits(tx.amount || 0, 0);
    txDto.status = TxStatus.Approved;
    txDto.token = token;

    return txDto;
  }

  private async getTonTxs(wallet: string, page: number = 1, offset: number = 10, withApiKey?: boolean) {
    const uri = `https://testnet.toncenter.com/api/v3/transactions?account=${wallet}&limit=${offset}&offset=${(page - 1) * offset}&sort=desc`;

    try {
      return (await firstValueFrom(this._http.get(uri))) as ToncenterRawTxListDto;
    } catch (e) {
      return null;
    }
  }

  private async getNotTxs(wallet: string, page: number = 1, offset: number = 10, withApiKey?: boolean) {
    const uri = `https://testnet.toncenter.com/api/v3/jetton/transfers?owner_address=${wallet}&jetton_wallet=${this.notContractAddress}&limit=${offset}&offset=${(page - 1) * offset}&sort=desc`;

    try {
      return (await firstValueFrom(this._http.get(uri))) as ToncenterJettonTxListDto;
    } catch (e) {
      return null;
    }
  }

  private async getUsdtTxs(wallet: string, page: number = 1, offset: number = 10, withApiKey?: boolean) {
    const uri = `https://testnet.toncenter.com/api/v3/jetton/transfers?owner_address=${wallet}&jetton_wallet=${this.usdtContractAddress}&limit=${offset}&offset=${(page - 1) * offset}&sort=desc`;

    try {
      return (await firstValueFrom(this._http.get(uri))) as ToncenterJettonTxListDto;
    } catch (e) {
      return null;
    }
  }

  private async requestGasPrice(withApiKey?: boolean) {
    // const apiKey = withApiKey ? this._env.polygonScanApiKey : "";
    // const uri = `${this._env.polygonScanApiUrl}?module=gastracker&action=gasoracle&apikey=${apiKey}`;

    // try {
    //   return (await firstValueFrom(this._http.get(uri))) as PolygonRawGasResponse;
    // } catch (error) {
    //   return null;
    // }
    return 0.05;
  }
}

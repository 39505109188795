import { IEnv } from "../app/common/interfaces/ienv";

export const environment: IEnv = {
  domain: "https://paynet-dev.unistory.app/",
  isProduction: false,
  // serverUrl: "/api/",
  serverUrl: "https://paynet-dev.unistory.app/api/",
  tronScanApiUrl: "https://apilist.tronscanapi.com/api/",
  usdtAddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
  tronGridUrl: "https://api.trongrid.io/",
  recaptchaKey: "6LclAicpAAAAACe4lcxzUzvlV6ocnPOCBqW4rgHv",
  polygonScanApiUrl: "https://api.polygonscan.com/api",
  polygonScanApiKey: "CX1NW8C9WTE9D73T9514EBNY58IAQREI81",
  subdomain: "https://paynet-shop.unistory.app/",
  tmaUrl: "https://t.me/paynet_dev_bot/paynet_dev_app",
};

import { trigger, state, style, AUTO_STYLE, transition, animate } from "@angular/animations";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IMenuItem } from "src/app/common/constants/menu-items.constant";
import { DomainHelper } from "src/app/common/utils/domain-helper.util";

@Component({
  selector: "app-menu-link-button",
  templateUrl: "./menu-link-button.component.html",
  styleUrls: ["./menu-link-button.component.css"],
  animations: [
    trigger("buttonExpand", [
      state("true", style({ width: AUTO_STYLE, visibility: AUTO_STYLE, margin: AUTO_STYLE })),
      state("false", style({ width: 0, visibility: "hidden", margin: "0" })),
      transition("false => true", animate("200ms")),
      transition("true => false", animate("200ms")),
    ]),
  ],
})
export class MenuLinkButtonComponent {
  @Input() item: IMenuItem = { link: "", icon: "", title: "", domain: "" };
  @Input() isFullMenu: boolean = true;

  @Output() onClick = new EventEmitter();

  constructor() {}

  public get isCurrent(): boolean {
    const isSameDomain = new URL(this.item.domain).host == window.location.host;
    return isSameDomain && window.location.pathname == "/" + this.item.link.split("?")[0];
  }

  public get isExternal() {
    const isSubdomain = DomainHelper.isSubdomain;
    const isSameDomain = new URL(this.item.domain).host == window.location.host;

    if (!isSubdomain && isSameDomain) {
      return false;
    }
    if (!isSubdomain && !isSameDomain) {
      return true;
    }
    if (isSubdomain && isSameDomain) {
      return false;
    }
    if (isSubdomain && !isSameDomain) {
      return true;
    }

    return false;
  }

  public onItemClick(event: MouseEvent) {
    if (this.isCurrent) {
      event.preventDefault();
    }

    if (!this.isCurrent && DomainHelper.isSubdomain && !DomainHelper.isChromeBrowser) {
      event.preventDefault();
      window.location.replace(this.item.domain + this.item.link);
    }

    this.onClick.emit();
  }
}

export class RouteConstants {
  public static login = "login";
  public static signup = "signup";
  public static logout = "logout";
  public static resetPassword = "reset-password";
  public static kyc = "kyc";
  public static myid = "myid";
  public static profile = "profile";
  public static depositary = "depositary";
  public static wallet = "depositary";
  public static transactions = "transactions";
  public static buySell = "buy-sell";
  public static cards = "cards";
  public static commissions = "commissions";
  public static calculator = "calculator";
  public static faq = "faq";

  public static admin = "admin";
  public static adminKyc = "kyc";
  public static adminHome = "home";
  public static adminAllTransactions = "all-transactions";
  public static adminTransactions = "transactions";
  public static adminUsers = "users";
  public static adminAdmins = "admins";
  public static reports = "reports";
  public static adminSecurity = "security";

  public static landing = "landing";

  public static verification = "verification";

  public static kycVerification = "kyc-verification";
  public static myIdRedirect = "kyc/register";

  public static blackList = "blocked";

  public static eventsListener = "events-listener";
}

import { Component, Input, OnInit } from "@angular/core";
import { Subject, debounceTime, switchMap } from "rxjs";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { CommissionService } from "src/app/services/commission.service";
import { GeneralPanelService } from "src/app/services/general-panel.service";
import { PolygonService } from "src/app/services/polygon.service";
import { RatesService } from "src/app/services/rates.service";

@Component({
  selector: "app-currency-calculator",
  templateUrl: "./currency-calculator.component.html",
  styleUrls: ["./currency-calculator.component.css"],
})
export class CurrencyCalculatorComponent implements OnInit {
  @Input() isOnLandingPage = false;

  type: "buy" | "sell" = "buy";
  selectedInputCurrency: CryptoSymbol = CryptoSymbol.Usdt;
  selectedOutputCurrency: CryptoSymbol = CryptoSymbol.Uzs;
  inputAmount: number | null = null;
  outputAmount: number | null = null;
  serviceFeePercents = 0;
  networkFee = 0;

  private $amount = new Subject<{ amount: number; currency: CryptoSymbol }>();
  private _rates: RateDto[] = [];
  private currencyRateFactor = 0.15;
  private polygonGasPrice = 0;

  constructor(
    private readonly _ratesService: RatesService,
    private readonly _commissionService: CommissionService,
    private readonly _polygonService: PolygonService,
    private readonly _generalPanelService: GeneralPanelService
  ) {
    this.$amount
      .pipe(
        debounceTime(300),
        switchMap(changedValue =>
          this._commissionService.getCommissionWithParams(changedValue.amount, changedValue.currency)
        )
      )
      .subscribe(async x => {
        if (x.withError || x.params == null) {
          this.serviceFeePercents = 0;
        } else {
          this.serviceFeePercents = x.params.percents;
        }

        await this.calculateNetworkFee();

        if (this.selectedOutputCurrency === CryptoSymbol.Uzs) {
          if (this.type === "buy") {
            this.outputAmount = this.outputAmount
              ? this.outputAmount + this.uzsCommission + this.networkFeeUzs
              : 0;
          } else if (this.type === "sell") {
            this.outputAmount = this.outputAmount
              ? this.outputAmount - this.uzsCommission - this.networkFeeUzs
              : 0;
          }
        }
      });
  }

  async ngOnInit() {
    await this.getRates();
    await this.getRateFactor();
    await this.getGasPrice();
    await this.calculateNetworkFee();
  }

  public get inputCurrencies() {
    return [
      CryptoSymbol.Usdt,
      CryptoSymbol.PolygonUsdt,
      CryptoSymbol.Trx,
      CryptoSymbol.Matic,
      CryptoSymbol.Uzs,
    ];
  }

  public get outputCurrencies() {
    return [
      CryptoSymbol.Usdt,
      CryptoSymbol.PolygonUsdt,
      CryptoSymbol.Trx,
      CryptoSymbol.Matic,
      CryptoSymbol.Uzs,
    ];
  }

  onChangeType(type: "buy" | "sell") {
    this.type = type;
    this.calculateOutputAmount();
  }

  onSelectInputCurrency(currency: CryptoSymbol) {
    this.selectedInputCurrency = currency;
    this.calculateOutputAmount();
    this.calculateNetworkFee();
  }

  onSelectOutputCurrency(currency: CryptoSymbol) {
    this.selectedOutputCurrency = currency;
    this.calculateOutputAmount();
  }

  calculateOutputAmount() {
    if (!this.inputAmount) {
      this.outputAmount = 0;
      return;
    }

    this.outputAmount = this.calculateRate(
      this.inputAmount,
      this.selectedInputCurrency,
      this.selectedOutputCurrency
    );

    if (this.inputAmount !== 0 && this.selectedInputCurrency !== CryptoSymbol.Uzs) {
      this.$amount.next({ amount: this.inputAmount, currency: this.selectedInputCurrency });
    } else if (this.outputAmount !== 0 && this.selectedOutputCurrency !== CryptoSymbol.Uzs) {
      this.$amount.next({ amount: this.outputAmount, currency: this.selectedOutputCurrency });
    } else {
      this.serviceFeePercents = 0;
    }
  }

  public get uzsCommission() {
    if (!this.inputAmount) return 0;

    const calculatedUzs = this.calculateRate(this.inputAmount, this.selectedInputCurrency, CryptoSymbol.Uzs);
    return calculatedUzs * (this.serviceFeePercents / 100);
  }

  public get networkCurrencyName() {
    switch (this.selectedInputCurrency) {
      case CryptoSymbol.Trx:
      case CryptoSymbol.Usdt:
        return "TRX";
      case CryptoSymbol.Matic:
      case CryptoSymbol.PolygonUsdt:
        return "MATIC";
      default:
        return "";
    }
  }

  public getCurrencyName(currency: CryptoSymbol) {
    return getCurrencyName(currency);
  }

  private async calculateNetworkFee() {
    this.networkFee = await this._commissionService.calculateNetworkCommission({
      currency: this.selectedInputCurrency,
      gasPrices: { polygonGasPrice: this.polygonGasPrice },
      amount: this.inputAmount?.toString() ?? "0",
    });
  }

  public get networkFeeUzs() {
    if (!this.networkFee || this._rates.length === 0) {
      return 0;
    }

    const networkFeeUzs = ConvertCurrencyHelper.convertToUzs(
      this.networkFee,
      this.selectedInputCurrency,
      this._rates
    );
    return networkFeeUzs;
  }

  private async getRates() {
    const res = await this._ratesService.getRates();
    this._rates = res.params!;
  }

  private calculateRate(value: number, selectedCurrency: CryptoSymbol, targetCurrency: CryptoSymbol) {
    if (selectedCurrency === targetCurrency) {
      return value;
    }

    let calculated = 0;

    if (targetCurrency === CryptoSymbol.Uzs) {
      const convertedToUzs = ConvertCurrencyHelper.convertToUzs(value, selectedCurrency, this._rates!);
      let adjustment = 0;
      if (this.type === "sell") {
        adjustment = 1 - this.currencyRateFactor / 100;
      } else {
        adjustment = 1 + this.currencyRateFactor / 100;
      }
      calculated = convertedToUzs * adjustment;
    }
    if (targetCurrency === CryptoSymbol.Usdt) {
      calculated = ConvertCurrencyHelper.convertToUsdt(value, selectedCurrency, this._rates!);
    }
    if (targetCurrency === CryptoSymbol.Trx) {
      calculated = ConvertCurrencyHelper.convertToTrx(value, selectedCurrency, this._rates!);
    }
    if (targetCurrency === CryptoSymbol.Matic) {
      calculated = ConvertCurrencyHelper.convertToMatic(value, selectedCurrency, this._rates!);
    }
    if (targetCurrency === CryptoSymbol.PolygonUsdt) {
      calculated = ConvertCurrencyHelper.convertToPolygonUsdt(value, selectedCurrency, this._rates!);
    }

    return calculated;
  }

  private async getGasPrice() {
    const polygonRes = await this._polygonService.getGasPrice();
    this.polygonGasPrice = polygonRes?.SafeGasPrice ? +polygonRes.SafeGasPrice : 0;
  }

  private async getRateFactor() {
    const res = await this._generalPanelService.getGeneralPanel();
    if (res.params) {
      this.currencyRateFactor = res.params.factor;
    }
  }

  ngOnDestroy(): void {
    this.$amount.unsubscribe();
  }
}

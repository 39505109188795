<router-outlet></router-outlet>

<app-toast aria-live="polite" aria-atomic="true"></app-toast>

<iframe
  id="subdomainFrame"
  [src]="domainEventsListenerUrl | safe"
  (load)="onMyFrameLoad()"
  style="width: 0; height: 0; border: none; position: absolute"
></iframe>

<div
  *ngIf="showFullscreenLoader"
  class="fixed right-0 top-0 z-[1000] flex h-screen w-full items-center justify-center bg-[#fff]"
>
  <app-loader />
</div>

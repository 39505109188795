<h2 class="title">{{ "Header.BuySell" | translate }}:</h2>

<div class="mt-5 grid grid-cols-2 gap-5 max-md:grid-cols-1 max-md:gap-3">
  <div *ngFor="let wallet of wallets" class="grid grid-cols-1 gap-5 max-md:gap-3">
    <div
      *ngFor="let balance of wallet.balances"
      class="flex gap-5 rounded-main border border-link-color px-7 py-5 max-md:flex-col"
    >
      <app-balance-card
        [balanceItem]="balance"
        [showRate]="true"
        [rates]="rates"
        class="flex-grow [&>div]:border-none [&>div]:p-0"
      />
      <div class="flex w-[12.5rem] flex-col gap-y-5 max-md:w-full">
        <button class="btn grow" (click)="openBuyModal(balance)">
          {{ "Buy.Title" | translate }}
          <app-svg-icon name="caret-down" class="ml-2 w-10" />
        </button>
        <button class="btn btn_pale py-1" (click)="openSellModal(balance)">
          {{ "Sell.Title" | translate }}
          <app-svg-icon name="caret-up" class="ml-2 w-10" />
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="wallets.length === 0">
    <app-balance-card [isLoading]="true" />
    <app-balance-card [isLoading]="true" />
    <app-balance-card [isLoading]="true" />
    <app-balance-card [isLoading]="true" />
  </ng-container>
</div>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvService } from "./env.service";
import { ethers } from "ethers";
import { firstValueFrom } from "rxjs";
import { PolygonRawTx } from "src/app/common/DTO/polygonscan/polygonscan-raw-tx.dto";
import { PolygonScanTxListDto } from "src/app/common/DTO/polygonscan/polygonscan-tx-list.dto";
import { Transaction } from "src/app/common/models/transaction";
import { CryptoToken } from "src/app/common/enums/crypto-token.enum";
import { TxType } from "src/app/common/enums/tx-type.enum";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { Network } from "src/app/common/enums/network.enum";
import { PolygonUsdtListDto } from "src/app/common/DTO/polygonscan/polygonscan-usdt-list.dto";
import { PolygonRawUsdtTx } from "src/app/common/DTO/polygonscan/polygonscan-raw-usdt-tx.dto";
import { PolygonRawGas, PolygonRawGasResponse } from "src/app/common/DTO/polygonscan/polygonscan-raw-gas.dto";
import { PolygonUsdtAbi, PolygonUsdtAddress } from "src/app/common/constants/polygon-usdt";

@Injectable({
  providedIn: "root",
})
export class PolygonService {
  private readonly txPerPage = 10;
  private readonly usdtContractAddress = PolygonUsdtAddress;
  private readonly polygonRpc = "https://polygon-rpc.com";
  private polygonProvider: ethers.providers.JsonRpcProvider;

  constructor(
    private readonly _http: HttpClient,
    private readonly _env: EnvService
  ) {
    this.polygonProvider = new ethers.providers.JsonRpcProvider(this.polygonRpc);
  }

  public async getMaticTransactions(wallet: string, page: number): Promise<Transaction[]> {
    const txs: Transaction[] = [];

    let response = await this.getMaticTxs(wallet, page, this.txPerPage, false);
    if (!response) return [];

    // If the rate limit reached, try again with the API key
    if (
      typeof response.result === "string" &&
      response.result.includes("Max rate limit reached, please use API Key for higher rate limit")
    ) {
      response = await this.getMaticTxs(wallet, page, this.txPerPage, true);
    }
    if (!response) return [];

    for (const tx of response.result) {
      const dto = new PolygonRawTx();
      Object.assign(dto, tx);
      const parsed = this.parseRawTx(dto, wallet);
      txs.push(parsed);
    }

    return txs;
  }

  public async getUsdtTransactions(wallet: string, page: number): Promise<Transaction[]> {
    const txs: Transaction[] = [];

    let response = await this.getUsdtTxs(wallet, page, this.txPerPage, false);
    if (!response) return [];

    if (
      typeof response.result === "string" &&
      response.result.includes("Max rate limit reached, please use API Key for higher rate limit")
    ) {
      response = await this.getUsdtTxs(wallet, page, this.txPerPage, true);
    }
    if (!response) return [];

    for (const tx of response.result) {
      const dto = new PolygonRawUsdtTx();
      Object.assign(dto, tx);
      const parsed = this.parseRawTx(dto, wallet);
      txs.push(parsed);
    }

    return txs;
  }

  public async getGasPrice(): Promise<PolygonRawGas | null> {
    let response = await this.requestGasPrice(false);
    if (!response) return null;

    if (
      typeof response.result === "string" &&
      response.result.includes("Max rate limit reached, please use API Key for higher rate limit")
    ) {
      response = await this.requestGasPrice(true);
    }
    if (!response || typeof response.result === "string") return null;

    return response.result;
  }

  public async estimateMaticTransferCost(
    amount: string,
    networkGasPrice: number,
    toAddress?: string
  ): Promise<number> {
    let gasAmount = 21000;

    if (toAddress && amount) {
      try {
        const transaction = {
          to: toAddress,
          value: ethers.utils.parseEther(amount),
          gasPrice: ethers.utils.parseUnits(networkGasPrice.toString(), "gwei"),
        };
        gasAmount = (await this.polygonProvider.estimateGas(transaction)).toNumber();
      } catch (error) {
        console.log(error);
      }
    }

    const gasPrice = networkGasPrice / 1e9;
    const fee = +gasAmount * +gasPrice;
    return fee;
  }

  public async estimatePolygonUsdtTransferCost(
    amount: string,
    networkGasPrice: number,
    fromAddress?: string,
    toAddress?: string
  ): Promise<number> {
    const usdtContract = new ethers.Contract(PolygonUsdtAddress, PolygonUsdtAbi, this.polygonProvider);
    let gasAmount = 77578;

    if (fromAddress && toAddress && amount) {
      try {
        gasAmount = (
          await usdtContract.estimateGas["transfer"](toAddress, ethers.utils.parseUnits(amount, 6))
        ).toNumber();
      } catch (error) {
        try {
          const manualGasLimit = 100000;
          const tx = {
            from: fromAddress,
            to: usdtContract.address,
            data: usdtContract.interface.encodeFunctionData("transfer", [
              toAddress,
              ethers.utils.parseUnits(amount, 6),
            ]),
            gasPrice: ethers.utils.parseUnits(networkGasPrice.toString(), "gwei"),
            gasLimit: manualGasLimit,
          };
          gasAmount = (await this.polygonProvider.estimateGas(tx)).toNumber();
        } catch (error) {
          console.log(error);
        }
      }
    }

    const gasPrice = networkGasPrice / 1e9;
    const fee = +gasAmount * +gasPrice;
    return fee;
  }

  private parseRawTx(tx: PolygonRawTx | PolygonRawUsdtTx, userWallet: string): Transaction {
    const txDto = new Transaction();

    txDto.from = tx.from;
    txDto.to = tx.to;
    txDto.hash = tx.hash;
    txDto.createdAt = new Date(+tx.timeStamp * 1000);
    txDto.timestamp = +tx.timeStamp * 1000;
    txDto.fee = +tx.gasPrice * +tx.gasUsed;
    txDto.id = tx.transactionIndex;
    txDto.type = tx.from.toLowerCase() === userWallet.toLowerCase() ? TxType.Out : TxType.In;
    txDto.network = Network.Polygon;

    if (tx instanceof PolygonRawTx) {
      txDto.amount = ethers.utils.formatUnits(tx.value ?? 0);
      txDto.status = tx.isError == "0" ? TxStatus.Approved : TxStatus.Canceled;
      txDto.token = CryptoToken.Matic;
      txDto.isCommission = tx.methodId === "0xa9059cbb";
    } else if (tx instanceof PolygonRawUsdtTx) {
      txDto.amount = ethers.utils.formatUnits(tx.value, tx.tokenDecimal);
      txDto.status = TxStatus.Approved;
      txDto.token = CryptoToken.PolygonUsdt;
    }

    return txDto;
  }

  private async getMaticTxs(wallet: string, page: number = 1, offset: number = 10, withApiKey?: boolean) {
    const apiKey = withApiKey ? this._env.polygonScanApiKey : "";
    const uri = `${this._env.polygonScanApiUrl}?module=account&action=txlist&address=${wallet}&startblock=0&endblock=99999999&page=${page}&offset=${offset}&sort=desc&apikey=${apiKey}`;

    try {
      return (await firstValueFrom(this._http.get(uri))) as PolygonScanTxListDto;
    } catch (e) {
      return null;
    }
  }

  private async getUsdtTxs(wallet: string, page: number = 1, offset: number = 10, withApiKey?: boolean) {
    const apiKey = withApiKey ? this._env.polygonScanApiKey : "";
    const uri = `${this._env.polygonScanApiUrl}?module=account&action=tokentx&contractaddress=${this.usdtContractAddress}&address=${wallet}&startblock=0
   &endblock=99999999&page=${page}&offset=${offset}&sort=desc&apikey=${apiKey}`;

    try {
      return (await firstValueFrom(this._http.get(uri))) as PolygonUsdtListDto;
    } catch (e) {
      return null;
    }
  }

  private async requestGasPrice(withApiKey?: boolean) {
    const apiKey = withApiKey ? this._env.polygonScanApiKey : "";
    const uri = `${this._env.polygonScanApiUrl}?module=gastracker&action=gasoracle&apikey=${apiKey}`;

    try {
      return (await firstValueFrom(this._http.get(uri))) as PolygonRawGasResponse;
    } catch (error) {
      return null;
    }
  }
}

import { AfterViewInit, Component } from "@angular/core";
import { MyIdQrPayloadDto } from "src/app/common/DTO/kyc/my-id-qr-payload.dto";
import { KycService } from "src/app/services/kyc.service";
import { EnvService } from "src/app/services/env.service";
import { RouteConstants } from "src/app/common/constants/route.constants";

@Component({
  templateUrl: "./myid.component.html",
  styleUrls: ["./myid.component.css"],
})
export class MyidComponent implements AfterViewInit {
  public qrBase64 = "";
  private _qrPayload: MyIdQrPayloadDto | null = null;
  public myIdLink = "";

  constructor(
    private readonly _kycService: KycService,
    private envService: EnvService
  ) {}

  public async ngAfterViewInit(): Promise<void> {
    const res = await this._kycService.buildMyIdQrPayload();
    if (res.withError) {
      return;
    }
    this._qrPayload = res.params;
    this.myIdLink = this.generateLink();
  }

  private generateLink(): string {
    if (this._qrPayload == null) {
      return "";
    }

    const url = this.envService.isProduction
      ? "https://myid.uz/api/v1/oauth2/authorization"
      : "https://devmyid.uz/api/v1/oauth2/authorization";

    const params = {
      client_id: this._qrPayload.clientId,
      method: this._qrPayload.verificationMethod,
      response_type: "code",
      scope: "address,contacts,doc_data,common_data",
      state: this._qrPayload.securityStamp,
      redirect_uri: `${this.envService.domain}${RouteConstants.myIdRedirect}`,
    };

    const link = `${url}?${Object.keys(params)
      .map(key => `${key}=${params[key as keyof typeof params]}`)
      .join("&")}`;

    return link;
  }
}

import {Component, OnInit} from "@angular/core";
import {ActivatedRouteSnapshot, Router} from "@angular/router";
import {KycService} from "src/app/services/kyc.service";
import {RouteConstants} from "src/app/common/constants/route.constants";


@Component({
  template: "",
})
export class MyidRedirectComponent implements OnInit {
  constructor(
    private _router: Router,
    private _kycService: KycService
  ) {}

  async ngOnInit(): Promise<void> {
    const currentRoute: ActivatedRouteSnapshot = this._router.routerState.snapshot.root;

    const code = currentRoute.queryParams['code'];
    const state = currentRoute.queryParams['state'];

    const result = await this._kycService.verifyKyc(code, state);
    this._router.navigateByUrl(`${RouteConstants.kycVerification}?isFail=${result}`);
  }
}

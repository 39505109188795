import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { RouteConstants } from "../constants/route.constants";
import { environment } from "src/environments/environment";
import { DomainHelper } from "../utils/domain-helper.util";

@Injectable({
  providedIn: "root",
})
export class BlackListGuard {
  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (DomainHelper.isSubdomain) {
      return true;
    }

    const isSubdomain = DomainHelper.isSubdomain;
    const data = await this._localStorage.getUserData();
    if (data?.blackList) {
      isSubdomain
        ? (window.location.href = `${environment.domain}/${RouteConstants.blackList}`)
        : this._router.navigate([`${RouteConstants.blackList}`]);
      return false;
    }

    return true;
  }
}

import { Resolve } from "@angular/router";
import { Injectable } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { UserDto } from "../DTO/users/user.dto";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { DomainHelper } from "../utils/domain-helper.util";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserResolver implements Resolve<any> {
  public user: UserDto | null = null;

  constructor(
    private readonly _userService: UserService,
    private readonly _localStorage: LocalStorageService
  ) {}

  public async resolve() {
    if (DomainHelper.isSubdomain && !DomainHelper.isChromeBrowser) {
      if (!window.location.href.includes("?tokens=")) {
        window.location.replace(environment.domain);
        return;
      }

      const urlSafeTokens = new URLSearchParams(window.location.search).get("tokens");

      if (!urlSafeTokens) {
        window.location.replace(environment.domain);
        return;
      }

      const tokens = JSON.parse(
        atob(urlSafeTokens.replace(/\-/g, "+").replace(/\_/g, "/").replace(/\%3D/g, "="))
      ) as {
        token: string;
        tokenLifetime: string;
        refresh: string;
        refreshLifetime: string;
      };

      if (
        (tokens.token == null || tokens.tokenLifetime == null) &&
        (tokens.refresh == null || tokens.refreshLifetime == null)
      ) {
        window.location.replace(environment.domain);
        return;
      }

      await this._localStorage.saveTokens({
        accessToken: tokens.token,
        accessExpiryAt: tokens.tokenLifetime,
        refreshToken: tokens.refresh,
        refreshExpiryAt: tokens.tokenLifetime,
      });
    }

    const response = await this._userService.getMe();
    if (response.withError || !response.params) {
      return;
    }

    this.user = response.params;
    await this._localStorage.saveUserData(this.user);

    return response.params;
  }
}

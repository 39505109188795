import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { environment } from "src/environments/environment";
import { DomainHelper } from "src/app/common/utils/domain-helper.util";

@Injectable({ providedIn: "root" })
export class AuthGuard {
  constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _router: Router
  ) {}

  public async canActivate() {
    if (DomainHelper.isSubdomain) {
      return true;
    }

    const token = await this._localStorage.accessToken();
    const tokenLifetime = await this._localStorage.accessTokenLifetime();
    const refresh = await this._localStorage.refreshToken();
    const refreshLifetime = await this._localStorage.refreshTokenLifetime();

    if ((token == null || tokenLifetime == null) && (refresh == null || refreshLifetime == null)) {
      DomainHelper.isSubdomain
        ? (window.location.href = environment.domain)
        : this._router.navigateByUrl("/");
      return false;
    }

    return true;
  }
}

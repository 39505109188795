import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { generate } from "lean-qr";
import { toSvgDataURL } from "lean-qr/extras/svg";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { Network } from "src/app/common/enums/network.enum";
import { WalletResolver } from "src/app/common/resolvers/wallet.resolver";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { DomainHelper } from "src/app/common/utils/domain-helper.util";
import { getNetworkName } from "src/app/common/utils/network-name-helper";
import { getWalletLink } from "src/app/common/utils/wallet-link-helper.util";
import { WalletService } from "src/app/services/wallet.service";

@Component({
  selector: "app-receive-modal",
  templateUrl: "./receive-modal.component.html",
  styleUrls: ["./receive-modal.component.css"],
})
export class ReceiveModalComponent implements OnInit {
  public internalError = false;
  public wallets: WalletDto[] = [];
  public qrBase64 = "";
  public selectedWallet: WalletDto = new WalletDto();
  Network = Network;

  constructor(
    private readonly _activeModal: NgbActiveModal,
    private readonly _walletResolver: WalletResolver,
    private readonly _walletService: WalletService
  ) {}

  async ngOnInit() {
    if (DomainHelper.isChromeBrowser) {
      this.wallets = await this._walletResolver.resolve();
    } else {
      const wallets = await this._walletService.getMy();
      this.wallets = wallets?.params || [];
    }
    this.selectedWallet = this.wallets[0];
    this.generateQr(this.selectedWallet.address);
  }

  public closeModal(): void {
    this._activeModal.close();
  }

  public get walletLink() {
    return getWalletLink(this.selectedWallet.network, this.selectedWallet.address);
  }

  public get networkName(): string {
    return getNetworkName(this.selectedWallet.network);
  }

  public get currencyName(): string {
    return getCurrencyName(this.selectedWallet.balances[0]?.currency);
  }

  public selectNetwork(network: Network): void {
    this.selectedWallet = this.wallets.find(x => x.network === network) || new WalletDto();
    this.generateQr(this.selectedWallet.address);
  }

  private generateQr(address: string) {
    const code = generate(address);
    this.qrBase64 = toSvgDataURL(code);
  }
}

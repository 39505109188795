import { CryptoSymbol } from "../enums/crypto-symbol.enum";

export function getCurrencyName(currency: CryptoSymbol) {
  switch (currency) {
    case CryptoSymbol.Usdt:
      return "USDT";
    case CryptoSymbol.Trx:
      return "TRX";
    case CryptoSymbol.Matic:
      return "MATIC";
    case CryptoSymbol.PolygonUsdt:
      return "USDT";
    case CryptoSymbol.Uzs:
      return "UZS";
    case CryptoSymbol.Tiyins:
      return "Tiyins";
    default:
      return "-";
  }
}

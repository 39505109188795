import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { Constants } from "src/app/common/constants/constants";
import { UrnConstants } from "src/app/common/constants/urn.constants";
import { EnvService } from "src/app/services/env.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { AccountRole } from "src/app/common/enums/account-role.enum";
import { TranslateService } from "@ngx-translate/core";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { EventBusService } from "src/app/services/event-bus.service";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { PlatformType } from "src/app/common/enums/platform-type.enum";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthModalComponent } from "../auth-modal/auth-modal.component";
import { ChangePasswordModalComponent } from "../change-password-modal/change-password-modal.component";
import { SessionService } from "src/app/services/session.service";
import { MENU_ITEMS } from "src/app/common/constants/menu-items.constant";
import { TimeHelperUtil } from "src/app/common/utils/time-helper.util";
import { KycDto } from "src/app/common/DTO/kyc/kyc.dto";
import { UserService } from "../../services/user.service";
import { ModalConstants } from "../../common/constants/modal-ids.constants";
import { ModalService } from "../_modal";
import { Subscription } from "rxjs";
import { ToastService } from "../../services/toast.service";
import { TonConnectService } from "src/app/services/ton-connect.service.ts.service";
import { TelegramMiniAppHelper } from "src/app/common/utils/telegram-mini-app-helper.util";

enum MenuItems {
  Profile,
  Cards,
  Logout,
  Wallet,
  ChangePassword,
  DeleteAccount,
}

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() public isLandingPage = false;
  public isAuth = false;
  public user = new UserDto();
  public platformTypes = PlatformType;
  public isMobileMenuOpen = false;
  public isTonWalletMenuOpen = false;
  public mobileMenuItems = MENU_ITEMS;
  public tonWalletAddress = "";
  public confirmationTime = 0;
  public ModalConstants = ModalConstants;
  public isTelegramMiniApp = TelegramMiniAppHelper.isMiniApp();

  private _timer: any;
  private _sessionTimeCounter: number = 0;
  private _sessionTimeSubscription: Subscription;

  private cleanUpFunctionsList: (VoidFunction | undefined)[] = [];

  constructor(
    private readonly _router: Router,
    private readonly _authService: AuthService,
    public readonly _localStorage: LocalStorageService,
    private readonly _envService: EnvService,
    private readonly _translateService: TranslateService,
    private readonly _eventBusService: EventBusService,
    private readonly _modalService: NgbModal,
    private readonly _sessionService: SessionService,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _userService: UserService,
    private readonly _modal: ModalService,
    private readonly _toastService: ToastService,
    private readonly tonConnectService: TonConnectService
  ) {
    this._eventBusService.handle(EventTypeConstants.ChangeAvatar, (data: string) => {
      this.user.avatar = data;
    });

    this._eventBusService.handle(EventTypeConstants.Logout, () => {
      this.isAuth = false;
      this.user = new UserDto();
    });

    this._eventBusService.handle(EventTypeConstants.UpdateVerificationStatus, (data: KycDto | null) => {
      this.user.kyc = data;
    });

    // Change language for subdomain
    this._eventBusService.handle(EventTypeConstants.ChangeLanguage, async (language: string) => {
      this.mobileMenuItems = this.mobileMenuItems.map(item => {
        let link = item.link;
        if (link.includes("language=")) {
          link = item.link.replace(/language=[a-z]{2}/, `language=${language}`);
        } else {
          link = `${item.link}?language=${language}`;
        }
        return { ...item, link };
      });
    });

    this._sessionTimeSubscription = this._sessionService.$timeCounter.subscribe((time: number) => {
      this._sessionTimeCounter = time;
      this._changeDetectorRef.detectChanges();
    });

    this.tonWalletAddress = tonConnectService.tonConnectUi?.account?.address || "";
  }

  async ngOnInit() {
    const user = await this._localStorage.getUserData();
    const tokenLifetime = await this._localStorage.accessTokenLifetime();
    const refreshLifetime = await this._localStorage.refreshTokenLifetime();
    const isValidToken = TimeHelperUtil.checkLifetimeIsValid(tokenLifetime);
    const isValidRefresh = TimeHelperUtil.checkLifetimeIsValid(refreshLifetime);

    if (user != null && (isValidToken || isValidRefresh)) {
      this.user = user;
      this.isAuth = true;
    }

    const remaining = await this._sessionService.getRemainingTime();
    if (remaining > 0 || isValidRefresh) {
      this._sessionService.startTimer();
    }

    const tonStatusUnsubscribe = this.tonConnectService?.tonConnectUi?.onStatusChange?.(wallet => {
      this.tonWalletAddress = wallet?.account?.address || "";
      this.isTonWalletMenuOpen = false;
    });
    this.cleanUpFunctionsList.push(tonStatusUnsubscribe);
  }

  ngOnDestroy() {
    this._sessionTimeSubscription.unsubscribe();
    this.cleanUpFunctionsList.map(cleanUp => cleanUp?.());
  }

  public returnMenuItemsNames() {
    return this.menuItems.map(item => item.title);
  }

  public async handleMenuItemClick(element: string) {
    const clickedItem = this.menuItems.find(item => item.title == element);

    if (clickedItem == null) {
      throw new Error("Element not found " + element);
    }

    switch (clickedItem.tag) {
      case MenuItems.Profile:
        this._router.navigate([RouteConstants.profile]);
        return;
      case MenuItems.Wallet:
        this._router.navigate([RouteConstants.wallet]);
        return;
      case MenuItems.Cards:
        this._router.navigate([RouteConstants.cards]);
        return;
      case MenuItems.ChangePassword:
        this.openChangePasswordModal();
        return;
      case MenuItems.DeleteAccount:
        this.openDeleteAccountConfirmation();
        return;
      case MenuItems.Logout:
        return await this.exit();
      default:
        break;
    }
  }

  public get isAdmin(): boolean {
    return (
      this.user.role == AccountRole.Admin ||
      this.user.role == AccountRole.SuperAdmin ||
      this.user.role == AccountRole.ObserverAmin
    );
  }

  public get menuItems() {
    const menu = [];

    if (this.isAdmin) {
      menu.push({ tag: MenuItems.Logout, title: this._translateService.instant("Header.Logout") });
    } else {
      menu.push(
        { tag: MenuItems.Wallet, title: this._translateService.instant("Wallet.My_wallet") },
        {
          tag: MenuItems.ChangePassword,
          title: this._translateService.instant("Header.Change_password"),
        },
        { tag: MenuItems.DeleteAccount, title: this._translateService.instant("Header.Delete_account") },
        { tag: MenuItems.Logout, title: this._translateService.instant("Header.Logout") }
      );
    }
    return menu;
  }

  public get profilePhoto() {
    if (this.user.avatar == null) {
      return Constants.ProfilePhotoBase;
    } else {
      return `${this._envService.serverUrl}${UrnConstants.StaticFolder}${this.user.avatar}`;
    }
  }

  public get sessionTime() {
    if (this._sessionTimeCounter <= 0) {
      return "00:00";
    }
    return new Date(this._sessionTimeCounter * 1000).toISOString().substring(14, 19);
  }

  public scrollToSectionOnLandingPage(sectionId: string) {
    const element = document.getElementById(sectionId);
    if (element == null) {
      return;
    }

    element.scrollIntoView({ behavior: "smooth" });
  }

  public openSignInModal() {
    const modalRef = this._modalService.open(AuthModalComponent);
    modalRef.componentInstance.variant = "sign-in";
  }

  public openSignUpModal() {
    const modalRef = this._modalService.open(AuthModalComponent);
    modalRef.componentInstance.variant = "sign-up";
  }

  public openChangePasswordModal() {
    const modalRef = this._modalService.open(ChangePasswordModalComponent);
    modalRef.componentInstance.userId = this.user.id;
  }

  public toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
    this.isTonWalletMenuOpen = false;
  }

  public toggleTonWalletMenu() {
    if (this.tonWalletAddress.length > 0) {
      return;
    }
    this.isTonWalletMenuOpen = !this.isTonWalletMenuOpen;
    this.isMobileMenuOpen = false;
  }

  public async exit(): Promise<void> {
    await this._authService.logout();
    this.isAuth = false;
    this.isMobileMenuOpen = false;

    await this._localStorage.clearTokens();
    await this._localStorage.removeUserData();

    this.user = new UserDto();

    if (
      this.user.role == AccountRole.Admin ||
      this.user.role == AccountRole.SuperAdmin ||
      this.user.role == AccountRole.ObserverAmin
    ) {
      this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.login}`);
    } else {
      this._router.navigateByUrl("/");
    }
  }

  public openDeleteAccountConfirmation() {
    this._modal.open(ModalConstants.DeactivateUserConfirmation);
    this.confirmationTime = 10;

    this._timer = setInterval(() => {
      this.confirmationTime -= 1;
      if (this.confirmationTime === 0) {
        clearInterval(this._timer);
      }
    }, 1000);
  }

  public handleDeleteAccountCancel() {
    this._modal.close(ModalConstants.DeactivateUserConfirmation);
  }

  public async handleDeleteAccountConfirm() {
    const response = await this._userService.deactivateUser(this.user.id);

    if (response.withError) {
      this._toastService.show(this._translateService.instant("Common.Unknown_error"));
    } else {
      this._toastService.show(this._translateService.instant("Profile.Delete_account_success"));
    }
    await this.exit();
  }

  public handleTonWalletConnect() {
    this.tonConnectService.openTelegramWallet();
  }
}

<header class="bg-box-background max-md:bg-secondary">
  <div class="container border-b border-border-input-color py-4 max-md:py-3">
    <div class="flex items-center justify-between">
      <a routerLink="/" class="w-[16rem] max-md:w-[8.75rem]">
        <img src="assets/icons/logo-new.png" alt="paynet-logo" />
      </a>

      <div *ngIf="isLandingPage" class="ml-auto mr-5 flex items-center space-x-5 max-md:hidden">
        <button class="btn btn_pale" (click)="scrollToSectionOnLandingPage('landing-commission')">
          {{ "Landing.Commission" | translate }}
        </button>
        <button class="btn btn_pale" (click)="scrollToSectionOnLandingPage('landing-calculator')">
          {{ "Landing.Calculator" | translate }}
        </button>
      </div>

      <ng-container *ngIf="isAuth">
        <p class="ml-auto text-xl text-[#1F0F57] max-md:hidden">
          {{ "Header.Session_timeout" | translate }} - {{ sessionTime }}
        </p>

        <app-dropdown
          [items]="returnMenuItemsNames()"
          (onSelect)="handleMenuItemClick($event)"
          class="ml-5 max-md:hidden"
        >
          <button class="flex items-center gap-2 rounded-tw bg-secondary px-7 py-5 text-sm font-semibold">
            <p class="max-md:hidden">{{ user.phoneNumber | phoneDisplayer }}</p>
            <img src="assets/icons/person.svg" alt="avatar" class="h-5 w-5 rounded-full object-cover" />
            <p class="uppercase tracking-wider">
              {{ user.kyc ? ("Header.Verified" | translate) : ("Header.Not_verified" | translate) }}
            </p>
            <img src="assets/icons/arrow.svg" alt="lang-arrow" class="w-3" />
          </button>
        </app-dropdown>

        <!-- Mobile -->
        <div class="text-end text-xs md:hidden">
          <img src="assets/icons/person.svg" alt="avatar" class="ml-auto h-5 w-5 rounded-full object-cover" />
          <p>{{ user.phoneNumber | phoneDisplayer }}</p>
          <p class="uppercase tracking-wider">
            {{ user.kyc ? ("Header.Verified" | translate) : ("Header.Not_verified" | translate) }}
          </p>
        </div>
      </ng-container>

      <app-language-selector
        class="block max-md:hidden"
        [ngClass]="isLandingPage || isAuth ? 'ml-5' : 'ml-auto'"
      />

      <ng-container *ngIf="!isAuth">
        <div class="ml-5 flex items-center space-x-5 max-md:hidden">
          <button class="btn btn_outline" (click)="openSignInModal()">
            {{ "Login.Sign_in" | translate }}
          </button>
          <button class="btn" (click)="openSignUpModal()">{{ "Login.Sign_up" | translate }}</button>
        </div>

        <!-- Login button in mobile -->
        <button class="h-10 w-10 md:hidden" (click)="openSignInModal()">
          <img src="assets/icons/person.svg" alt="login" class="w-full" />
        </button>
      </ng-container>
    </div>
  </div>

  <div *ngIf="isLandingPage" class="overflow-hidden bg-accent py-1">
    <p
      class="animate-marquee whitespace-nowrap text-xs font-bold uppercase tracking-widest text-text-secondary"
    >
      {{ "Landing.Description" | translate }}
    </p>
  </div>

  <!-- Mobile subheader -->
  <div class="flex items-center justify-between bg-select-bg px-4 py-2 md:hidden">
    <ng-container *ngIf="isLandingPage">
      <button (click)="scrollToSectionOnLandingPage('landing-calculator')" class="w-8">
        <img src="assets/icons/calculator.svg" alt="calculator" />
      </button>
      <button (click)="scrollToSectionOnLandingPage('landing-commission')" class="w-8">
        <img src="assets/icons/percent.svg" alt="commission" />
      </button>
    </ng-container>

    <p *ngIf="isAuth" class="min-w-[40%] text-sm text-text-secondary">
      {{ "Header.Session_timeout" | translate }} - {{ sessionTime }}
    </p>
    <app-language-selector />
    <button
      *ngIf="isAuth"
      class="flex items-center justify-center gap-2 bg-accent p-2 text-sm font-semibold uppercase text-text-secondary"
      (click)="toggleMobileMenu()"
    >
      {{ "Header.Menu" | translate }}
      <app-svg-icon *ngIf="isMobileMenuOpen" name="menu-close" class="w-5" />
      <app-svg-icon *ngIf="!isMobileMenuOpen" name="menu-burger" class="w-5" />
    </button>
  </div>

  <!-- Mobile menu -->
  <div *ngIf="isMobileMenuOpen" class="space-y-1 bg-select-bg pb-1">
    <app-menu-link-button *ngFor="let item of mobileMenuItems" [item]="item" (onClick)="toggleMobileMenu()" />
    <button
      class="flex w-full items-center gap-x-5 bg-secondary px-5 py-2"
      (click)="openChangePasswordModal()"
    >
      <app-svg-icon name="lock-closed" class="w-7 stroke-text-primary" />
      <span class="text-sm font-semibold uppercase text-text-primary">
        {{ "Header.Change_password" | translate }}
      </span>
    </button>
    <button
      class="flex w-full items-center gap-x-5 bg-secondary px-5 py-2"
      (click)="openDeleteAccountConfirmation()"
    >
      <app-svg-icon name="delete-account" class="w-7 stroke-text-primary" />
      <span class="text-sm font-semibold uppercase text-text-primary">
        {{ "Header.Delete_account" | translate }}
      </span>
    </button>
    <button class="flex w-full items-center gap-x-5 px-5 py-2" (click)="exit()">
      <app-svg-icon name="menu/exit-outline" class="w-7 stroke-text-secondary" />
      <span class="text-sm font-semibold uppercase text-text-secondary">
        {{ "Header.Logout" | translate }}
      </span>
    </button>
  </div>
</header>

<jw-modal [id]="ModalConstants.DeactivateUserConfirmation">
  <h2 class="title-modal">
    {{ "Profile.Delete_account_title" | translate }}
  </h2>

  <ng-container *ngIf="confirmationTime > 0">
    <p class="mt-4 text-center">{{ "Send.Confirmation_available_after" | translate }}</p>
    <div
      class="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-[#1F0F57] text-2xl font-bold text-text-secondary"
    >
      {{ confirmationTime }}
    </div>
  </ng-container>

  <div class="mt-5 flex items-center justify-center">
    <button class="btn h-[3rem] w-[12.5rem] px-5 py-2 text-sm" (click)="handleDeleteAccountCancel()">
      {{ "Common.No" | translate }}
    </button>
    <button
      class="btn ml-4 h-[3rem] w-[12.5rem] px-5 py-2 text-sm"
      [class.btn_danger]="true"
      (click)="handleDeleteAccountConfirm()"
      [disabled]="confirmationTime != 0"
    >
      {{ "Common.Yes" | translate }}
    </button>
  </div>
</jw-modal>

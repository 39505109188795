<ng-container [ngSwitch]="step">
  <h2 class="title-modal">{{ "Send.Title" | translate }}</h2>
  <app-stepper [totalSteps]="3" [currentStep]="step" class="mb-5 mt-2 block" />

  <ng-template ngSwitchCase="1">
    <div class="space-y-5">
      <div *ngFor="let wallet of wallets" class="grid grid-cols-1 gap-y-5 max-md:gap-y-3">
        <app-balance-card
          *ngFor="let balance of wallet.balances"
          [isShort]="true"
          [withSelect]="true"
          [balanceItem]="balance"
          (onSelect)="onSelectBalance($event, wallet.address)"
          [selected]="
            selectedWalletBalance.address === wallet.address && selectedWalletBalance.balances[0] === balance
          "
        />
      </div>
      <app-balance-card *ngIf="wallets.length === 0" [isShort]="true" [isLoading]="true" />
      <hr class="border-border-input-color" />
      <form class="flex flex-col gap-y-5" [formGroup]="sendForm" (ngSubmit)="submitInput()">
        <fieldset [disabled]="wallets.length === 0" class="flex flex-col space-y-5">
          <app-input
            [title]="'Send.Amount' | translate"
            type="number"
            name="amount"
            id="amount"
            placeholder="100"
            formControlName="amount"
            appPositiveDecimals
            [errorMsg]="amountError"
          />
          <app-input
            [title]="'Send.Receiver' | translate"
            type="text"
            name="token"
            id="token"
            [placeholder]="walletPlaceholder"
            formControlName="to"
            [errorMsg]="toError"
          />
        </fieldset>
        <p class="flex items-center justify-between text-text-pale">
          {{ "Send.Network_commission" | translate }}:
          <span>{{ networkFee | mfeCustom: true }} {{ nativeCurrencyName }}</span>
        </p>
        <p class="flex items-center justify-between text-text-pale">
          {{ "Send.Receive" | translate }}:
          <span class="text-2xl font-bold">
            {{ receiveAmount | mfeCustom: true }} {{ selectedCurrencyName }}
          </span>
        </p>
        <p class="flex items-center justify-between text-text-pale">
          {{ "Send.Total" | translate }}:
          <span class="text-2xl font-bold">
            {{ sendAmount | mfeCustom: true }} {{ selectedCurrencyName }}
          </span>
        </p>
        <button type="submit" class="btn" [disabled]="sendForm.invalid || !!amountError || isCalculating">
          {{ "Common.Next" | translate }}
        </button>
      </form>
    </div>
  </ng-template>

  <ng-template ngSwitchCase="2">
    <div class="space-y-5">
      <app-balance-card [isShort]="true" [balanceItem]="selectedWalletBalance.balances[0]" />
      <p class="flex flex-wrap items-center justify-between text-text-pale max-md:max-w-[calc(100vw-6rem)]">
        {{ "Transaction.From" | translate }}:
        <span class="truncate font-bold text-link-color">{{ selectedWalletBalance.address }}</span>
      </p>
      <p class="flex flex-wrap items-center justify-between text-text-pale max-md:max-w-[calc(100vw-6rem)]">
        {{ "Transaction.To" | translate }}:
        <span class="truncate font-bold text-link-color">{{ sendForm.get("to")?.value }}</span>
      </p>
      <p class="flex items-center justify-between text-text-pale">
        {{ "Send.Network_commission" | translate }}:
        <span>{{ networkFee | mfeCustom: true }} {{ nativeCurrencyName }}</span>
      </p>
      <p class="flex items-center justify-between text-text-pale">
        {{ "Send.Receive" | translate }}:
        <span class="text-2xl font-bold">
          {{ receiveAmount | mfeCustom: true }} {{ selectedCurrencyName }}
        </span>
      </p>
      <p class="flex items-center justify-between text-text-pale">
        {{ "Send.Total" | translate }}:
        <span class="text-2xl font-bold">{{ sendAmount | mfeCustom: true }} {{ selectedCurrencyName }}</span>
      </p>
      <hr class="border-border-input-color" />
      <p class="text-center">{{ "Send.Check_address" | translate }}</p>
      <ng-container *ngIf="confirmationTime > 0">
        <p class="text-center">{{ "Send.Confirmation_available_after" | translate }}</p>
        <div
          class="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-[#1F0F57] text-2xl font-bold text-text-secondary"
        >
          {{ confirmationTime }}
        </div>
      </ng-container>
      <div class="flex items-stretch gap-5">
        <button
          type="button"
          class="flex w-16 items-center justify-center rounded-xl bg-select-bg px-5"
          (click)="onBack()"
        >
          <img src="assets/icons/arrow-back-outline.svg" alt="back" />
        </button>
        <button class="btn flex-grow" [disabled]="confirmationTime > 0 || isPending" (click)="onConfirm()">
          {{ "Common.Confirm" | translate }}
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template ngSwitchCase="3">
    <div class="space-y-5">
      <p class="text-xl">{{ "Send.In_progress" | translate }}</p>
      <div class="space-y-2 rounded-main border border-link-color px-5 py-2">
        <app-balance-card
          [isShort]="true"
          [balanceItem]="selectedWalletBalance.balances[0]"
          class="[&>div]:border-none [&>div]:p-0"
        />
        <p class="flex items-center justify-between text-text-pale max-md:max-w-[calc(100vw-6rem)]">
          {{ "Transaction.From" | translate }}:
          <span class="truncate font-bold text-link-color">{{ selectedWalletBalance.address }}</span>
        </p>
        <p class="flex items-center justify-between text-text-pale max-md:max-w-[calc(100vw-6rem)]">
          {{ "Transaction.To" | translate }}:
          <span class="truncate font-bold text-link-color">{{ sendForm.get("to")?.value }}</span>
        </p>
        <p class="flex items-center justify-between text-text-pale">
          {{ "Send.Network_commission" | translate }}:
          <span>{{ networkFee | mfeCustom: true }} {{ nativeCurrencyName }}</span>
        </p>
        <p class="flex items-center justify-between text-text-pale">
          {{ "Send.Receive" | translate }}:
          <span class="text-2xl font-bold">
            {{ receiveAmount | mfeCustom: true }} {{ selectedCurrencyName }}
          </span>
        </p>
        <p class="flex items-center justify-between text-text-pale">
          {{ "Send.Total" | translate }}:
          <span class="text-2xl font-bold">
            {{ sendAmount | mfeCustom: true }} {{ selectedCurrencyName }}
          </span>
        </p>
      </div>
      <hr class="border-border-input-color" />
      <button class="btn w-full" (click)="closeModal()">{{ "Send.Go_to_wallet" | translate }}</button>
    </div>
  </ng-template>

  <p *ngIf="businessError" class="mt-5 text-danger">{{ businessError }}</p>

  <ng-container *ngIf="step !== 3">
    <hr class="mt-5 border-border-input-color" />
    <div class="disclaimer mt-5 text-sm font-normal">
      {{ "Send.Disclaimer_1" | translate: { network: networkName } }}
      <br />
      {{ "Send.Disclaimer_2" | translate: { amount: minimumAmount, currency: nativeCurrencyName } }}
      <br />
      {{ "Send.Disclaimer_3" | translate: { currency: nativeCurrencyName } }}
    </div>
  </ng-container>
</ng-container>

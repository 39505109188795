import { environment } from "src/environments/environment";
import { RouteConstants } from "../constants/route.constants";

export class DomainHelper {
  public static get isChromeBrowser() {
    return !!(window as any)?.chrome;
  }

  public static get isSubdomain() {
    return window.location.host == new URL(environment.subdomain).host;
  }

  public static get eventsListenerUrl() {
    return DomainHelper.isSubdomain
      ? `${environment.domain}${RouteConstants.eventsListener}`
      : `${environment.subdomain}${RouteConstants.eventsListener}`;
  }
}

import { Component, Input } from "@angular/core";
import { TxType } from "src/app/common/enums/tx-type.enum";
import { TxParserUtil } from "src/app/common/utils/tx-parser.util";
import { TranslateService } from "@ngx-translate/core";
import { Transaction } from "src/app/common/models/transaction";
import { CryptoToken } from "src/app/common/enums/crypto-token.enum";
import { TxStatus } from "src/app/common/enums/tx-status.enum";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { MfeCustomPipe } from "src/app/common/pipes/mfe-custom.pipe";
import { getWalletLink } from "src/app/common/utils/wallet-link-helper.util";
import { getTransactionLink } from "src/app/common/utils/transaction-link-helper.util";

@Component({
  selector: "app-transaction-list-item",
  templateUrl: "./transaction-list-item.component.html",
  styleUrls: ["./transaction-list-item.component.css"],
})
export class TransactionListItemComponent {
  @Input() txDto = new Transaction();
  @Input() fullSize = false;

  public TxType = TxType;

  constructor(private readonly _translateService: TranslateService) {}

  public get balanceChange(): string {
    const amount = new MfeCustomPipe().transform(this.txDto.amount, true);

    if (amount === "0") {
      return amount;
    }

    switch (this.txDto.type) {
      case TxType.In:
        return `+${amount}`;
      case TxType.Out:
        return `-${amount}`;
      default:
        return amount;
    }
  }

  public get tokenName(): string {
    switch (this.txDto.token) {
      case CryptoToken.Usdt:
        return "USDT";
      case CryptoToken.Trx:
        return "TRX";
      case CryptoToken.PolygonUsdt:
        return "USDT";
      case CryptoToken.Matic:
        return "MATIC";
      default:
        return "-";
    }
  }

  public get txDestination(): string {
    if (!this.txDto.type) {
      return "Транзакция";
    }

    const destinationTag = TxParserUtil.getTxDestination(this.txDto.type);
    return this._translateService.instant(destinationTag);
  }

  public get txStatus(): string {
    const statusTag = TxParserUtil.getTxStatus(this.txDto.status);
    return this._translateService.instant(statusTag);
  }

  public get txDate(): string {
    return this.txDto.createdAt.toString();
  }

  public get iconName(): CryptoSymbol {
    switch (this.txDto.token) {
      case CryptoToken.Usdt:
        return CryptoSymbol.Usdt;
      case CryptoToken.Trx:
        return CryptoSymbol.Trx;
      case CryptoToken.PolygonUsdt:
        return CryptoSymbol.PolygonUsdt;
      case CryptoToken.Matic:
        return CryptoSymbol.Matic;
      default:
        return CryptoSymbol.Usdt;
    }
  }

  public get statusColor(): string {
    if (this.txDto.status === TxStatus.Approved) {
      return "bg-accent";
    } else if (this.txDto.status === TxStatus.OnConfirm || this.txDto.status === TxStatus.WaitingApprove) {
      return "bg-waiting";
    } else {
      return "bg-danger";
    }
  }

  public returnWalletLink(address: string): string {
    return getWalletLink(this.txDto.network, address);
  }

  public get txLink(): string {
    return getTransactionLink(this.txDto.network, this.txDto.hash);
  }
}

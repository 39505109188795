import { trigger, state, style, AUTO_STYLE, transition, animate } from "@angular/animations";
import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { UserDto } from "src/app/common/DTO/users/user.dto";
import { DomainEventsConstants } from "src/app/common/constants/domain-event.constants";
import { EventTypeConstants } from "src/app/common/constants/event-type.constants";
import { MENU_ITEMS } from "src/app/common/constants/menu-items.constant";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { AccountRole } from "src/app/common/enums/account-role.enum";
import { DomainHelper } from "src/app/common/utils/domain-helper.util";
import { AuthService } from "src/app/services/auth.service";
import { EventBusService } from "src/app/services/event-bus.service";
import { LocalStorageService } from "src/app/services/local-storage.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-client-sidebar",
  templateUrl: "./client-sidebar.component.html",
  styleUrls: ["./client-sidebar.component.css"],
  animations: [
    trigger("buttonExpand", [
      state("true", style({ width: AUTO_STYLE, visibility: AUTO_STYLE, margin: AUTO_STYLE })),
      state("false", style({ width: 0, visibility: "hidden", margin: "0" })),
      transition("false => true", animate("200ms")),
      transition("true => false", animate("200ms")),
    ]),
  ],
})
export class ClientSidebarComponent {
  public menuItems = MENU_ITEMS;
  public isFullMenu = true;
  private user = new UserDto();
  private subdomainFrame: HTMLIFrameElement | null = null;

  constructor(
    private _authService: AuthService,
    private _localStorage: LocalStorageService,
    private _router: Router,
    private _eventBusService: EventBusService
  ) {
    if (!DomainHelper.isChromeBrowser) {
      this.getTokens().then(async tokens => {
        const language = await this._localStorage.getLanguage();
        this.menuItems = MENU_ITEMS.map(item => {
          if (item.domain !== environment.subdomain) {
            return item;
          }
          const urlSafeTokens = btoa(JSON.stringify(tokens)).replace(/\+/g, "-").replace(/\//g, "_");
          return { ...item, link: `${item.link}?tokens=${urlSafeTokens}&language=${language}` };
        });
      });
    }

    this._localStorage.getUserData().then(x => {
      if (x != null) this.user = x;
    });

    this._localStorage.getSidebarState().then(x => {
      this.isFullMenu = x;
    });

    this.subdomainFrame = document.getElementById("subdomainFrame") as HTMLIFrameElement;

    // Change subdomain link on language change
    this._eventBusService.handle(EventTypeConstants.ChangeLanguage, (language: string) => {
      if (DomainHelper.isChromeBrowser) return;

      this.menuItems = this.menuItems.map(item => {
        let link = item.link;
        if (link.includes("language=")) {
          link = item.link.replace(/language=[a-z]{2}/, `language=${language}`);
        } else {
          link = `${item.link}?language=${language}`;
        }
        return { ...item, link };
      });
    });
  }

  public onLogout() {
    this.exit();
  }

  private async exit(): Promise<void> {
    if (DomainHelper.isSubdomain) {
      await this._localStorage.clearTokens();
      await this._localStorage.removeUserData();
      window.location.replace(`${environment.domain}${RouteConstants.logout}`);
      return;
    }

    await this._authService.logout();

    if (
      this.user.role == AccountRole.Admin ||
      this.user.role == AccountRole.SuperAdmin ||
      this.user.role == AccountRole.ObserverAmin
    ) {
      this._router.navigateByUrl(`${RouteConstants.admin}/${RouteConstants.login}`);
    } else {
      this.subdomainFrame?.contentWindow?.postMessage(
        { key: DomainEventsConstants.userLogout },
        DomainHelper.eventsListenerUrl
      );
      DomainHelper.isSubdomain
        ? (window.location.href = environment.domain)
        : this._router.navigateByUrl("/");
    }

    await this._localStorage.clearTokens();
    this._localStorage.removeUserData();
  }

  public toggleMenu() {
    this.isFullMenu = !this.isFullMenu;
    this._localStorage.saveSidebarState(this.isFullMenu);
    this.subdomainFrame?.contentWindow?.postMessage(
      { key: DomainEventsConstants.sidebarState, params: this.isFullMenu },
      DomainHelper.eventsListenerUrl
    );
  }

  private async getTokens() {
    const token = await this._localStorage.accessToken();
    const tokenLifetime = await this._localStorage.accessTokenLifetime();
    const refresh = await this._localStorage.refreshToken();
    const refreshLifetime = await this._localStorage.refreshTokenLifetime();
    return { token, tokenLifetime, refresh, refreshLifetime };
  }
}

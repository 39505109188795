import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Subject, debounceTime, switchMap } from "rxjs";
import { CardDto } from "src/app/common/DTO/cards/card.dto";
import { BuyOrderDto } from "src/app/common/DTO/crypto/buy-order.dto";
import { SellOrderDto } from "src/app/common/DTO/crypto/sell-order.dto";
import { RateDto } from "src/app/common/DTO/rates/rate.dto";
import { WalletBalanceDto } from "src/app/common/DTO/wallets/wallet-balance.dto";
import { RouteConstants } from "src/app/common/constants/route.constants";
import { CryptoErrorCode } from "src/app/common/enums/crypto-error-code.enum";
import { CryptoSymbol } from "src/app/common/enums/crypto-symbol.enum";
import { ConvertCurrencyHelper } from "src/app/common/utils/convert-currency-helper.util";
import { getCurrencyName } from "src/app/common/utils/currency-name-helper.util";
import { DomainHelper } from "src/app/common/utils/domain-helper.util";
import { CardService } from "src/app/services/card.service";
import { CommissionService } from "src/app/services/commission.service";
import { CryptoService } from "src/app/services/crypto.service";
import { GeneralPanelService } from "src/app/services/general-panel.service";
import { PolygonService } from "src/app/services/polygon.service";
import { RatesService } from "src/app/services/rates.service";
import { ToastService } from "src/app/services/toast.service";
import { environment } from "src/environments/environment";

enum BuySellStep {
  Input = 1,
  CardSelect = 2,
  Processing = 3,
}

@Component({
  selector: "app-buy-sell-modal",
  templateUrl: "./buy-sell-modal.component.html",
  styleUrls: ["./buy-sell-modal.component.css"],
})
export class BuySellModalComponent implements OnInit {
  @Input() variant: "buy" | "sell" = "buy";
  @Input() selectedBalance: WalletBalanceDto = new WalletBalanceDto();

  step: BuySellStep = BuySellStep.Input;
  rates: RateDto[] = [];
  bankCards: CardDto[] = [];
  selectedBankCard: CardDto | null = null;
  amount: number | null = null;
  serviceFeePercents = 0;
  feeAmount = 0;
  uzsAmount = 0;
  isPending = false;
  networkFee = 0;

  private $amount = new Subject<number>();
  private polygonGasPrice = 0;
  private currencyRateFactor = 0.15;

  constructor(
    private _cardService: CardService,
    private readonly _translateService: TranslateService,
    private readonly _commissionService: CommissionService,
    private readonly _ratesService: RatesService,
    private readonly _router: Router,
    private readonly _activeModal: NgbActiveModal,
    private readonly _cryptoService: CryptoService,
    private readonly _toastService: ToastService,
    private readonly _polygonService: PolygonService,
    private readonly _generalPanelService: GeneralPanelService
  ) {
    this.$amount
      .pipe(
        debounceTime(300),
        switchMap(amount =>
          this._commissionService.getCommissionWithParams(amount, this.selectedBalance!.currency)
        )
      )
      .subscribe(async x => {
        if (x.withError || x.params == null) {
          this.serviceFeePercents = 0;
        } else {
          this.serviceFeePercents = x.params.percents;
        }
        await this.calculateNetworkFee();
        this.feeAmount =
          this.serviceFeePercents && this.uzsAmount ? (this.uzsAmount * this.serviceFeePercents) / 100 : 0;
      });
  }

  async ngOnInit() {
    await this.getRates();
    await this.getRateFactor();
    await this.getGasPrice();
    await this.calculateNetworkFee();
    this.loadBankCards();
  }

  closeModal() {
    this._activeModal.close();
  }

  onBack() {
    if (this.step === BuySellStep.CardSelect) {
      this.step = BuySellStep.Input;
    }
  }

  goToBankCards() {
    const route = `${RouteConstants.depositary}/${RouteConstants.cards}`;
    DomainHelper.isSubdomain
      ? (window.location.href = environment.domain + route)
      : this._router.navigateByUrl(route);
    this.closeModal();
  }

  goToWallet() {
    DomainHelper.isSubdomain
      ? (window.location.href = environment.domain + RouteConstants.wallet)
      : this._router.navigateByUrl(RouteConstants.wallet);
    this.closeModal();
  }

  public get currencySymbol() {
    return getCurrencyName(this.selectedBalance.currency);
  }

  public get currencyNetwork() {
    switch (this.selectedBalance.currency) {
      case CryptoSymbol.Matic:
      case CryptoSymbol.PolygonUsdt:
        return "Polygon";
      case CryptoSymbol.Trx:
      case CryptoSymbol.Usdt:
        return "TRC20";
      default:
        return "";
    }
  }

  public get nativeCurrencyName() {
    switch (this.selectedBalance.currency) {
      case CryptoSymbol.Matic:
      case CryptoSymbol.PolygonUsdt:
        return "MATIC";
      case CryptoSymbol.Trx:
      case CryptoSymbol.Usdt:
        return "TRX";
      default:
        return "";
    }
  }

  calculateUzs() {
    if (!this.amount || !this.selectedBalance || +this.amount === 0) {
      this.uzsAmount = 0;
      return;
    }
    const convertedToUzs = ConvertCurrencyHelper.convertToUzs(
      this.amount,
      this.selectedBalance.currency,
      this.rates
    );
    let adjustment = 0;
    if (this.variant === "sell") {
      adjustment = 1 - this.currencyRateFactor / 100;
    } else {
      adjustment = 1 + this.currencyRateFactor / 100;
    }
    this.uzsAmount = convertedToUzs * adjustment;
    this.$amount.next(this.amount);
  }

  selectCard(card: CardDto) {
    this.selectedBankCard = card;
  }

  public get amountError() {
    if (!this.amount) {
      return null;
    }
    if (+this.amount === 0) {
      return this._translateService.instant("Common.Field_not_filled");
    }
    if (this.totalAmount <= 0) {
      return this._translateService.instant("Sell.Min_value_error");
    }
    return null;
  }

  onInputSubmit() {
    if (!this.amount || +this.amount === 0) {
      return;
    }
    this.step = BuySellStep.CardSelect;
  }

  onCheckout() {
    if (!this.selectedBankCard) {
      return;
    }
    if (this.variant === "buy") {
      this.doBuy();
    }
    if (this.variant === "sell") {
      this.doSell();
    }
  }

  public get networkFeeUzs() {
    if (!this.selectedBalance || !this.networkFee || this.rates.length === 0) {
      return 0;
    }

    const networkFeeUzs = ConvertCurrencyHelper.convertToUzs(
      this.networkFee,
      this.selectedBalance!.currency,
      this.rates
    );
    return networkFeeUzs;
  }

  public get totalAmount() {
    if (!this.amount || this.amount === 0) {
      return 0;
    }

    if (this.variant === "buy") {
      return this.uzsAmount + this.feeAmount + this.networkFeeUzs;
    } else if (this.variant === "sell") {
      return this.uzsAmount - this.feeAmount - this.networkFeeUzs;
    } else {
      return 0;
    }
  }

  private async calculateNetworkFee() {
    this.networkFee = await this._commissionService.calculateNetworkCommission({
      currency: this.selectedBalance.currency,
      gasPrices: { polygonGasPrice: this.polygonGasPrice },
      amount: this.amount?.toString() ?? "0",
    });
  }

  private async doSell() {
    this.isPending = true;
    const order: SellOrderDto = {
      sellAmount: this.amount!,
      sellCurrency: this.selectedBalance!.currency,
      cardId: this.selectedBankCard!.id,
    };

    const res = await this._cryptoService.sellCrypto(order);
    if (res.withError) {
      if (res.errorCode === CryptoErrorCode.Disabled) {
        this._toastService.show(this._translateService.instant("Sell.Sell_disabled"));
      } else {
        this._toastService.show(this._translateService.instant("Common.Unknown_error"));
      }
    } else {
      this.step = BuySellStep.Processing;
    }
    this.isPending = false;
  }

  private async doBuy() {
    this.isPending = true;
    const order: BuyOrderDto = {
      buyAmount: this.amount!,
      buyCurrency: this.selectedBalance!.currency,
      cardId: this.selectedBankCard!.id,
    };

    const res = await this._cryptoService.buyCrypto(order);
    if (res.withError) {
      if (res.errorCode === CryptoErrorCode.Disabled) {
        this._toastService.show(this._translateService.instant("Buy.Buy_disabled"));
      } else {
        this._toastService.show(this._translateService.instant("Common.Unknown_error"));
      }
    } else {
      this.step = BuySellStep.Processing;
    }
    this.isPending = false;
  }

  private async loadBankCards() {
    const res = await this._cardService.getCards();
    if (res.withError || !res.params) {
      return;
    }
    this.bankCards = res.params;
    this.selectedBankCard = this.bankCards[0];
  }

  private async getRates() {
    const res = await this._ratesService.getRates();
    if (res.withError || !res.params) {
      return;
    }
    this.rates = res.params;
  }

  private async getGasPrice() {
    if (
      this.selectedBalance.currency === CryptoSymbol.Matic ||
      this.selectedBalance.currency === CryptoSymbol.PolygonUsdt
    ) {
      const polygonRes = await this._polygonService.getGasPrice();
      this.polygonGasPrice = polygonRes?.SafeGasPrice ? +polygonRes.SafeGasPrice : 0;
    }
  }

  private async getRateFactor() {
    const res = await this._generalPanelService.getGeneralPanel();
    if (res.params) {
      this.currencyRateFactor = res.params.factor;
    }
  }
}

import { Injectable } from "@angular/core";
import { WalletDto } from "src/app/common/DTO/wallets/wallet.dto";
import { WalletService } from "src/app/services/wallet.service";
import { Resolve } from "@angular/router";
import { DomainHelper } from "../utils/domain-helper.util";

@Injectable({
  providedIn: "root",
})
export class WalletResolver implements Resolve<WalletDto[]> {
  public wallets: WalletDto[] = [];

  constructor(private readonly _walletService: WalletService) {}

  public async resolve(): Promise<WalletDto[]> {
    if (!DomainHelper.isChromeBrowser) {
      return [];
    }
    const wallets = await this._walletService.getMy();
    this.wallets = wallets?.params || [];
    return this.wallets;
  }
}
